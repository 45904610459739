.scroll-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1000;
}

.scroll-to-top button {
  /* padding: 10px 20px; */
  font-size: 18px;
  /* background-color:  #683fea; */
  background: none;
  text-align: center;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: opacity 0.3s;
  transform: rotate(-90deg);
  /* opacity: 0.7; */
}

.scroll-to-top button:hover {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .scroll-to-top{
    right: 20px;
  }
}
