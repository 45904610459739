.bcb {
  color: #fff;
  letter-spacing: 0.72px;
  font-size: 18px;
  line-height: 33px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.bcb h1,
.bcb h2,
.bcb h3,
.bcb h4,
.bcb h5,
.bcb h6 {
  align-self: flex-start;
  text-align: left;
  margin-bottom: 8px;
}
.bcb p,
.bcb a,
.bcb span {
  color: #fff;
  letter-spacing: 0.72px;
  font-size: 18px;
  line-height: 33px;
  font-weight: 400;
  margin-bottom: 24px;
  text-decoration: none;
  list-style-type: none;
  align-self: flex-start;
}
.bcb li,
.bcb ol,
.bcb ul {
  margin-bottom: 16px;
}
.bcb img {
  width: 1000px !important;
  margin-bottom: 24px;
  border-radius: 4px;
  height: 416px !important;
  object-fit: cover !important;
}
.li {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 767px) {
  .bcb {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bcb p,
  .bcb ol,
  .bcb ul{
    margin-bottom: 24px !important;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.4px;
    font-weight: 400;
    width: 100%;
    /* background: red; */
  }
  .bcb img {
    width: 100% !important;
    margin-bottom: 24px;
    border-radius: 4px;
    height: 200px !important;
    /* object-fit: cover !important; */
  }
  .bcb h1,
  .bcb h2,
  .bcb h3,
  .bcb h4,
  .bcb h5,
  .bcb h6 {
    align-self: flex-start;
    text-align: left;
    margin-bottom: 10px !important;
  }
}
