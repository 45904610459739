@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "ClashGrotesk-Extralight";
  src: url("./fonts/ClashGrotesk-Extralight.woff2") format("woff2"),
    url("./fonts/ClashGrotesk-Extralight.woff") format("woff"),
    url("./fonts/ClashGrotesk-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Light";
  src: url("./fonts/ClashGrotesk-Light.woff2") format("woff2"),
    url("./fonts/ClashGrotesk-Light.woff") format("woff"),
    url("./fonts/ClashGrotesk-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Regular";
  src: url("./fonts/ClashGrotesk-Regular.woff2") format("woff2"),
    url("./fonts/ClashGrotesk-Regular.woff") format("woff"),
    url("./fonts/ClashGrotesk-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Medium";
  src: url("./fonts/ClashGrotesk-Medium.woff2") format("woff2"),
    url("./fonts/ClashGrotesk-Medium.woff") format("woff"),
    url("./fonts/ClashGrotesk-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Semibold";
  src: url("./fonts/ClashGrotesk-Semibold.woff2") format("woff2"),
    url("./fonts/ClashGrotesk-Semibold.woff") format("woff"),
    url("./fonts/ClashGrotesk-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Bold";
  src: url("./fonts/ClashGrotesk-Bold.woff2") format("woff2"),
    url("./fonts/ClashGrotesk-Bold.woff") format("woff"),
    url("./fonts/ClashGrotesk-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

/* Style the entire scrollbar */
::-webkit-scrollbar {
  width: 7px; /* Width of the vertical scrollbar */
  height: 12px; /* Height of the horizontal scrollbar */
}

/* Style the scroll bar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  /* background: linear-gradient(
    to bottom,
    #d4f36b 0%,
    #d4f36b 38%,
    #a47cf3 76%,
    #683fea 100%
  ); Color of the scroll bar thumb */
  background: #555;
  border-radius: 6px; /* Roundness of the scroll bar thumb */
  width: 5px;
}

/* Style the scroll bar thumb when hovered */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovered */
}

/* Style the scroll bar track */
::-webkit-scrollbar-track {
  background: #000000; /* Color of the track */
}

* {
  font-family: "Red Hat Text", sans-serif;
  margin: 0;
  padding: 0;
}

.redHat-regular {
  font-weight: 400;
  font-family: "Red Hat Text", sans-serif;
}
.redHat-medium {
  font-weight: 500;
  font-family: "Red Hat Text", sans-serif;
}
.redHat-semiBold {
  font-weight: 600;
  font-family: "Red Hat Text", sans-serif;
}
.redHat-bold {
  font-weight: 700;
  font-family: "Red Hat Text", sans-serif;
}
button {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
div {
  -webkit-tap-highlight-color: transparent;
}
.navbar {
  width: 100%;
  height: 100px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 10000;
  /* transition: height 1s ease; */
}
.navbar-brand {
  margin-left: 64px;
}
.expanded {
  justify-content: flex-start;
  height: 100vh;
  flex-direction: column;
  transition: height 0.8s ease;
}
.menu-Link {
  width: 100%;
  margin-top: 48px;
  /* background: red; */
  flex-direction: column;
}
.menu-link {
  font-weight: 600 !important;
  font-size: 18px !important;
}
.dm-link {
  font-size: 16px !important;
}
.logo {
  width: 114px;
  height: 30px;
}
.navbar-link {
  /* background: red; */
  display: flex;
}
.navbar-ul {
  width: 500px;
  /* background: red; */
  /* width: 306px; */
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
}
.nav-item {
  position: relative;
  margin-bottom: 0px !important;
}
.nav-link {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 21px;
  padding: 10px;
  display: inline-block;
  border-radius: 4px;
}
.navBar-btn {
  width: 200px;
  height: 53px;
  border-radius: 100px;
  border: none;
  background: #fff;
  margin-right: 64px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
}
.navBar-btn1 {
  width: 104px;
  height: 53px;
  border-radius: 100px;
  border: none;
  background: #fff;
  margin-right: 64px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
}

.login-btn {
  color: #fff;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0px;
  margin-right: 20px;
}
.menu-toggle {
  background: none;
  border: none;
  display: none;
}
.menu-ls {
  width: 100%;
  position: absolute;
  bottom: 0;
  align-self: center;
  /* background-color: red; */
  bottom: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* align-items: center; */
}
.con-btn {
  width: 80vw;
}
.sign-up {
  width: 90%;
  display: flex;
  align-items: center;
  border-radius: 100px;
  height: 53px;
  background: #fff;
  margin-left: 20px;
  justify-content: center;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: 500;
}
.dropdown-cont {
  padding-top: 12px;
}
.dropdown-menu {
  display: block;
  position: absolute;
  top: 100%; /* Position it directly below the nav item */
  left: 0;
  background-color: #000;
  border: 1.5px solid #262626;
  z-index: 10;
  border-radius: 4px;
  overflow: hidden;
  width: max-content; /* Adjust width as needed */
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  /* margin-top: 12px; */
}

.dropdown-item {
  margin: 0;
}

.dropdown-link {
  display: block;
  /* padding-left: 10px; */
  color: #fff;
  font-size: 16px;
  line-height: 21px;
  text-decoration: none;
  margin-bottom: 16px;
  padding-right: 36px;
  font-weight: 400;
  cursor: pointer;
}
.link-last-child {
  margin-bottom: 0;
}

.dropdown-link:hover {
  color: #ddd;
}

/* ///////////////////////////////////// */
/* ///////////////////////////////////// */
/* //////////////Home Section////////// */
/* /////////////////////////////////// */
/* //////////////////////////////////// */

.bodySection {
  width: 100%;
  /* height: 100vh; */
  background: #000000;
}
.topper {
  width: 100%;
  height: 91px;
}
.bannerText {
  width: 100%;
  height: auto;
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.innerTextSection {
  /* max-width: 856px; */
  height: auto;
  /* background-color: #a47cf3; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.gradient-text {
  font-size: 72px;
  letter-spacing: 2%;
  background: linear-gradient(
    to right,
    #d4f36b 0%,
    #d4f36b 38%,
    #a47cf3 76%,
    #683fea 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "ClashGrotesk-Medium";
  margin-top: 94px;
  display: inline-block;
}
.text-alt {
  font-size: 72px;
  letter-spacing: 2%;
  color: #fff;
  text-align: center;
  font-family: "ClashGrotesk-Medium";
  margin-bottom: 24px;
}
.mt-text {
  max-width: 660px;
  align-self: center;
  color: #fff;
  text-align: center;
  /* background-color: red; */
  font-size: 18px;
  letter-spacing: 0.72px;
  line-height: 33px;
  /* font-weight: 400; */
}
.bg-img-cont {
  width: 100%;
  height: 523px;
  background-image: url("../assests/images/hero-bg.png");
  background-size: cover;
  /* background-color: red; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 40px;
}
.store-btn-wrap {
  display: flex;
}
.store-btn {
  width: 165.5px;
  height: 56px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.store-btn p {
  font-size: 16px;
  color: #fff;
  margin-left: 10px;
  line-height: 21px;
}
.app-store-btn {
  margin-right: 16px;
}

.section2 {
  width: 100%;
  /* position: absolute; */
  background: #000000;
  /* height: 600px; */
}
.gradient-bg {
  width: 100%;
  height: 220px;
  position: relative;
  background: linear-gradient(
    to bottom,
    #0000001a 0%,
    #00000066 25%,
    #000000cc 50%,
    #000000e6 75%,
    #000000 100%
  );
  /* background-color: #758f1d5b; */
  top: -110px;
}
.g-text {
  margin-top: -110px;
  font-size: 56px;
}
.text-alt2 {
  font-size: 56px;
}

.modal-section {
  width: 100%;
  margin-top: 56px;
  display: flex;
  /* background-color: red; */
  justify-content: center;
}
.wrap1 {
  margin-right: 32px;
}
.card-modal {
  max-width: 568px;
  height: 300px;
  background: #141414;
  border-radius: 24px;
}
.card-modal svg {
  margin-top: 27.69px;
  margin-left: 36.69px;
  margin-bottom: 44.64px;
}
.modal-hText {
  font-size: 32px;
  color: #fff;
  font-family: "ClashGrotesk-Medium";
  /* font-weight: 400; */
  margin-left: 32px;
  letter-spacing: 2%;
}
.modal-pText {
  font-size: 16px;
  color: #ffffffcc;
  max-width: 504px;
  padding-left: 32px;
  padding-right: 32px;
  line-height: 33px;
  letter-spacing: 0.72px;
  margin-top: 10px;
  /* font-weight: 400; */
}

.btn-sec {
  width: 568px;
  height: 80px;
  background: #fff;
  border-radius: 100px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.btn-sec p {
  margin-left: 32px;
  font-size: 24px;
}
.btn-sec svg {
  margin-right: 12px;
}
.section3 {
  width: 100%;
  /* background: red; */
  /* justify-content: center; */
  background-image: url("../assests/images/bb5.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-text2 {
  font-size: 56px;
  letter-spacing: 2%;
  background: linear-gradient(
    to right,
    #d4f36b 0%,
    #d4f36b 38%,
    #a47cf3 76%,
    #683fea 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "ClashGrotesk-Medium";
  text-align: center;
  margin-bottom: 24px;
  /* display: inline-block; */
}

.mg-top-220 {
  margin-top: 220px;
}
.mg-top-200 {
  margin-top: 200px;
}
.mg-bottom-56 {
  margin-bottom: 56px;
}

.content-section {
  margin-top: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.store-frame {
  width: 568px;
  height: 600px;
  /* background-image: url("../assests/images/y-frame.png"); */
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frameText {
  font-size: 40px;
  font-family: "ClashGrotesk-Medium";
  margin-top: 24px;
  letter-spacing: 2%;
  line-height: auto;
  font-weight: 500;
  margin-bottom: 24px;
}
.st-btn {
  width: 167.5px;
  height: 56px;
  background: #000000;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  cursor: pointer;
}
.for-apple {
  /* margin-top: 24; */
}
.st-btn p {
  font-size: 16px;
  color: #fff;
  margin-left: 10px;
  line-height: 21px;
  /* font-weight: 300; */
  letter-spacing: 0;
}
.content-frame {
  width: 568px;
  height: 600px;
  /* background-image: url("../assests/images/y-frame.png"); */
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 32px;
  /* background: red; */
  justify-content: space-between;
}
.card-frame {
  width: 100%;
  height: 185px;
  background: #141414;
  border-radius: 24px;
  /* margin-bottom: 32px; */
}
.card-frame-header {
  display: flex;
  margin-left: 32px;
  margin-top: 28px;
  align-items: center;
  margin-bottom: 12px;
  /* justify-content: center; */
}
.card-frame-header p {
  font-size: 32px;
  color: #fff;
  font-family: "ClashGrotesk-Medium";
  /* font-weight: 400; */
  margin-left: 12px;
  letter-spacing: 2%;
}
.card-frame-body-text {
  font-size: 16px;
  color: #ffffffcc;
  margin-left: 32px;
  margin-right: 32px;
  line-height: 33px;
  letter-spacing: 0.72px;
}

.tz-project-section {
  width: 100%;
  /* background-color: red; */
}
.tkc-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 56px;
}
.tokenized-content {
  width: 160px;
  height: 392px;
  /* background: red; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tokenized-content img {
  width: 100%;
  border-radius: 24px;
  /* border: 5px solid #fff;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; */
}
.enlargeFrame {
  width: auto;
  display: flex;
  align-items: center;
  margin-left: 32px;
  /* flex-direction: column; */
}
.img-frame img {
  max-width: 791px;
  max-height: 392px;
  transition: 2s;
}
.curvedArrow {
  position: absolute;
  margin-top: -30vh;
  right: 320px;
}
.activeIndex {
  border: 5px solid #fff;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.text-frame {
  width: 466px;
  max-height: 500px;
  /* background-color: #000000; */
  margin-left: -281px;
  position: relative;
}
.text-frame::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 24px;
  padding: 2px;
  background: linear-gradient(
      to right,
      #d4f36b 0%,
      #d4f36b 38%,
      #a47cf3 76%,
      #683fea 100%
    )
    border-box;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.text-frame-inner {
  width: 100%;
  height: 100%;
  background-color: #000000;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.text-frame-wrap {
  margin-left: 32px;
  margin-right: 50px;
  margin-top: 32px;
  margin-bottom: 32px;
}
.tf-p1 {
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 2%;
  color: #fff;
  /* margin-left: 35px; */
  font-family: "Rock Salt", cursive;
  font-weight: 400;
  width: 100%;
  margin-bottom: 20px;
}
.tf-p2 {
  font-size: 16px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Architects Daughter", cursive;
  font-weight: 400;
  max-width: 402px;
}
.rept {
  width: 100%;
  /* background-color: #000000; */
  background-image: url("../assests/images/bb4.webp");
  background-size: cover;
  background-position: center;
}
.partners {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: red; */
}
.pt-img-first-child {
  margin-right: 96px;
}

.wps {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wps-header-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wg-text {
  font-size: 56px;
  letter-spacing: 2%;
  background: linear-gradient(
    to right,
    #ffffff 17%,
    #d4f36b 53%,
    #a47cf3 76%,
    #683fea 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "ClashGrotesk-Medium";
  display: inline-block;
}
.flow-btn-cont {
  margin-left: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 184px;
}
.flow-btn {
  cursor: pointer;
}

.testimonials-container {
  width: 80%;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.testnimonial-cont {
  margin-top: 48px;
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
  width: 100vw;
}
.testnimonial-cont::-webkit-scrollbar{
   display: none;
}
.testimonial-cards {
  flex: 0 0 568px;
  height: 297px;
  background-color: #141414;
  border-radius: 30px;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
}

.tc-cards {
  flex: 0 0 568px;
  height: 297px;
  background-color: #141414;
  border-radius: 30px;
  margin-right: 20px;
  position: relative;
}

.testimonial-cards2 {
  width: 568px;
  height: 297px;
}

.testimonial-cards::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 30px;
  padding: 2px;
  background: linear-gradient(
      to right,
      #d4f36b 0%,
      #d4f36b 38%,
      #a47cf3 76%,
      #683fea 100%
    )
    border-box;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease;
}
.testimonial-cards.active::before,
.testimonial-cards:hover::before {
  opacity: 1; /* Show the pseudo-element on hover */
}

.spacer {
  flex: 0 0 13vw;
  height: 100px;
  /* background: green; */
}

.ts-cont-margin {
  margin-right: 20px;
}

.testCard-inner {
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
}

.testCard-head {
  display: flex;
  align-items: center;
  margin-bottom: 19px;
}

.testCard-text {
  margin-left: 20px;
}

.ts-name {
  font-size: 28px;
  margin-bottom: 0;
  margin-bottom: 10px;
}

.ts-title {
  font-size: 18px;
  color: #fff;
  letter-spacing: 0px;
  font-family: "ClashGrotesk-Medium";
  font-weight: 400;
}

.feedback {
  line-height: 33px;
  font-size: 16px;
  letter-spacing: 0.72px;
  font-weight: 300;
}

.dot-cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  margin: 0 5px;
  cursor: pointer;
  transition: width 0.3s, background-color 0.3s;
}

.reserve-cont {
  width: 100%;
  background: #000000;
}
.rsc-content-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-cont {
  /* background: red; */
  margin-top: 244px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

@keyframes rotate-left-right {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.main-cont img {
  width: 719px;
  height: 544px;
  animation: rotate-left-right 15s infinite;
}
.main-cont-text {
  margin-left: 33px;
  max-width: 536px;
}
.text1 {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 2px;
  font-family: "ClashGrotesk-Medium";
  font-weight: 400;
  color: #d4f36b;
  margin-bottom: 6px;
}
.text-head {
  font-family: "ClashGrotesk-Medium";
  color: #fff;
  font-size: 56px;
  line-height: 48px;
  font-weight: 400;
}
.text-wrap {
  margin-top: 32px;
}
.text-wrap p {
  color: #ffffff99;
  font-size: 18px;
  line-height: 28px;
  font-family: 300;
  letter-spacing: 0%;
}
.head-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.head-wrap p {
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0%;
  font-family: "ClashGrotesk-Medium";
  font-weight: 400;
  margin-left: 8px;
}

.factory-faq {
  width: 100%;
  background-image: url("../assests/images/bb.webp");
  background-size: cover;
  background-position: center;
}
.structure {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 80px;
  overflow: hidden;
}
.structure-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /* background-color: red; */
}
.structure-cont1 {
  margin-right: 78px;
  height: 600px;
  justify-content: space-between;
}
.structure-cont1 img:first-child {
  margin-bottom: 20px;
}
.structure-cont2 img {
  max-width: 500px;
}
.structure-cont2 img:first-child {
  margin-bottom: 23px;
}
.fact-text-section {
  display: flex;
  /* background: red; */
}
.fact-text-section img {
  width: 64px;
  height: 64px;
  margin-right: 24px;
}
.fact-text-wrap {
  max-width: 454px;
}
.fact-text-wrap p:first-child {
  color: #fff;
  font-size: 28px;
  letter-spacing: 0px;
  font-family: "ClashGrotesk-Medium";
  margin-bottom: 8px;
}
.fact-text-wrap p:last-child {
  color: #ffffffb3;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 0px;
}
.faq {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.faq-container {
  width: 65vw;
  margin: 0 auto;
  /* padding: 20px; */
  /* background-color: #f9f9f9; */
  border-radius: 10px;
}

.faq-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
}

.faq-list {
  list-style-type: none;
  padding: 0;
}

.faq-item {
  margin-bottom: 16px;
  transition: background-color 0.3s;
  border: 2px solid #ffffff4d;
  border-radius: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 32px;
  padding-right: 32px;
}

.faq-question {
  width: 100%;
  background: none;
  border: none;
  text-align: left;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px; */
}
.faq-question p {
  color: #fff;
  font-family: "ClashGrotesk-Medium";
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.72px;
}
.faq-answer {
  margin-top: 8px;
  /* margin: 10px 0 0 0; */
  border-radius: 5px;
  color: #cccccc;
  font-size: 18px;
  padding-right: 70px;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.mg-FAQ {
  margin-bottom: 40px !important;
}
.SHQ {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.btn-cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.cta {
  display: flex;
  background-image: url("../assests/images/ctaBanner.svg");
  background-size: cover;
  background-position: center;
  max-width: 1168px;
  border-radius: 32px;
  align-items: center;
}
.cta-p {
  max-width: 427px;
  font-size: 64px;
  color: #fff;
  line-height: 87px;
  letter-spacing: -1px;
  font-family: "ClashGrotesk-Medium";
  padding-left: 96px;
  /* margin-top: 53px; */
}
.cta-content {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  margin-left: 66px;
  /* background: red; */
  margin-top: 53px;
  margin-bottom: 53px;
}
.cta-content p {
  font-size: 20px;
  line-height: 33px;
  letter-spacing: 0.72px;
  color: #fff;
  margin-bottom: 24px;
  padding-right: 96px;
  max-width: 483px;
}
.btn-long {
  width: 688px;
  background-color: #fff;
  border-radius: 100px;
  height: 64px;
  border: none;
  font-size: 20px;
  letter-spacing: 0.72px;
  line-height: 24px;
  font-weight: 600;
  color: #000000;
}
.btn {
  width: 158px;
  background-color: #fff;
  border-radius: 64px;
  height: 56px;
  border: none;
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 24px;
  font-weight: 500;
  color: #000000;
}
.footer-section {
  width: 100%;
}
.footer-cont {
  width: 100%;
  border-top: 1px solid #ffffff2e;
  border-bottom: 1px solid #ffffff2e;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* flex-wrap: wrap; */
}
.card {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}
.card-head {
  font-size: 18px;
  font-family: "ClashGrotesk-Medium";
  color: #fff;
  letter-spacing: 0%;
}
.card-p {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}
.card-p-mg-top1 {
  margin-top: 56.7px;
}
.card-p-mg-top2 {
  margin-top: 24px;
}
.card-p-mg-top3 {
  margin-top: 13px;
}
.card1 {
  margin-left: 32px;
}
.fbtn-cont {
  display: flex;
  margin-top: 13px;
}
.card2 {
  margin-left: 151.08px;
  display: block;
  width: 203px;
}
.card3 {
  width: 203px;
  display: block;
  margin-left: 36px;
}
.socials {
  width: 100%;
  margin-top: 32px;
}
.s-card-head {
  font-size: 16px;
  font-family: "ClashGrotesk-Medium";
  color: #fff;
  letter-spacing: 0%;
}
.s-icons {
  width: 141.19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;
}

.footer-bottom {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  height: 83px;
}

.ptl {
  width: 283px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 32px;
  margin-top: 32px;
  left: 10px;
  position: absolute;
  /* justify-self: flex-start; */
}
.link {
  text-decoration: none;
}
.pp-section {
  width: 100%;
  background-image: url("../assests/images/bb7.webp");
  background-size: cover;
  background-position: center;
}
.pp-text {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  letter-spacing: 0%;
  line-height: 19px;
  font-weight: 400;
}
.copyright {
  color: #fff;
  align-self: center;
  justify-self: center;
  font-size: 12px;
  letter-spacing: 0%;
  line-height: 14px;

  /* margin-left: 332px; */
}

.policies-wrap p:first-child {
  color: #fff;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: 0.72px;
  font-weight: 600;
  margin-bottom: 10px;
}
.policies-wrap p:last-child {
  font-size: 18px;
  line-height: 33px;
}
.terms-p {
  font-size: 18px;
}
/* /////////////////////////////// */
/* ////////////////////////////// */
/* /////////About section/////// */
/* ///////////////////////////// */
/* //////////////////////////// */

.black-bg {
  width: 100%;
  background: #000000;
}
.about-sec {
  width: 100%;
  background-image: url("../assests/images/bb6.webp");
  background-size: cover;
  background-position: center;
}
.abt-nav {
  width: 100%;
  padding-top: 48px;
  margin-bottom: 80px;
  position: fixed;
}
.abt-nav svg {
  margin-left: 64px;
}
.abt-text-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.abt-text-wrap {
  max-width: 764px;
  margin-bottom: 232px;
}
.abt-g-text {
  font-size: 72px;
  letter-spacing: 2%;
  background: linear-gradient(
    to right,
    #d4f36b 0%,
    #d4f36b 38%,
    #a47cf3 76%,
    #683fea 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "ClashGrotesk-Medium";
  margin-bottom: 40px;
  display: inline-block;
}
.abt-p-text {
  margin-bottom: 24px;
  font-size: 18px;
  letter-spacing: 0.72px;
  line-height: 33px;
  color: #fff;
}
.terms-use {
  width: 100%;
  background-image: url("../assests/images/bb8.svg");
  background-size: cover;
  background-position: top;
}
.last-p {
  color: #ffffffcc;
  font-size: 18px;
  line-height: 33px;
  letter-spacing: 0.72px;
}

/* //////////////// */
/* /////input///// */
/* /////////////// */

.input-cont {
  width: 664px;
  /* background: red; */
  display: flex;
  flex-direction: column;
}
.input_label {
  color: #fff;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.15px;
  font-weight: 500;
  margin-bottom: 12px;
}
.input-wrap1 {
  margin-top: 8px;
  margin-bottom: 40px;
}
.input-wrap {
  margin-bottom: 40px;
}
.input {
  width: 100%;
  height: 64px;
  background: #ffffff1a;
  border-radius: 8px;
  /* outline: none; */
  padding-left: 24px;
  padding-right: 24px;
  color: #fff;
  border: none;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  /* border: 1px solid #ffffff80; */
}
.input:focus {
  outline: 1px solid #ffffff80;
}
.textarea-input {
  width: 100%;
  min-height: 84px;
  padding-left: 24px;
  padding-right: 24px;
  color: #fff;
  border: none;
  padding-top: 16px;
  font-size: 16px;
  font-weight: 500;
  background: #ffffff1a;
  border-radius: 8px;
  resize: none; /* Prevent manual resizing */
  overflow: hidden; /* Hide scrollbar */
  box-sizing: border-box;
}
.textarea-input:focus {
  outline: 1px solid #ffffff80;
}

/* mobile footer */
.mobile-footer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mf-content {
  width: 100%;
  border: 1px solid #ffffff2e;
  /* background: red; */
  align-self: center;
  padding-left: 30px;
}
.bottom-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* //////////////// ///////////////////////////////////////////////////////////////////////////////////*/
/* ////////////// ////////////////////////////////////////////////////////////////////////////////////*/
/* ////////////////////////////////////// Blog Section ///////////////////////////////////////////////*/
/* ///////////////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////////////// */
.blog-section {
  width: 100%;
  background-image: url("../assests/images/bb6.webp");
  background-size: cover;
  background-position: top;
  overflow-y: hidden;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
}
.blog-nav {
  width: 100%;
  /* background: red; */
  padding-top: 48px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.blogArrow-back {
  position: absolute;
  left: 64px;
}
.blog-topper {
  width: 100%;
  height: 161px;
}
.tab-Bar {
  width: 100%;
  height: 69px;
  border: 2px solid #2b2b2b;
  border-left: none;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-link-cont {
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background: red; */

}
/* .b-tab-link-cont{
  overflow-x: scroll;

} */
.tab-link-cont::-webkit-scrollbar {
  display: none;
}
.tab-links {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0%;
  font-weight: 400;
  flex-shrink: 0;
  margin-right: 64px;
}
.blog-content {
  width: 100%;
  height: 69.8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  overflow-y: auto;
}
.main-blog-section {
  /* width: 80%; */
  /* height: auto; */
  /* background: red; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 56px;
  row-gap: 120px;
  padding-top: 80px;
}
.blog-section1 {
  column-gap: 53px !important;
  row-gap: 100px !important;
}
.blog-content-card {
  width: 350px;
  /* background: blue; */
}
.blog-card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.bch-wrap {
  display: flex;
  align-items: center;
}
.bch-p1 {
  font-size: 15px !important;
}
.bch-p {
  font-family: "ClashGrotesk-Semibold" !important;
  font-family: 600 !important;
  color: #fff !important;
  margin-left: 8px;
  font-size: 18px !important;
  letter-spacing: 0%;
}
.blog-card-header p {
  color: #ffffff99;
  font-size: 16px;
  letter-spacing: 0%;
  font-family: "ClashGrotesk-Regular";
  font-weight: 400;
}
.db-date {
  font-size: 13px !important;
}
.db-header {
  margin-bottom: 12px !important;
  align-items: flex-end !important;
}
.line-seperator {
  width: 100%;
  height: 4px;
  border-radius: 30px;
  background: linear-gradient(
    to right,
    #d4f36b 0%,
    #d4f36b 38%,
    #a47cf3 76%,
    #683fea 100%
  );
}
.db-seperator {
  height: 3px !important;
}
.blog-title {
  font-size: 24px;
  font-family: "ClashGrotesk-Semibold" !important;
  font-family: 600 !important;
  color: #fff !important;
  padding-top: 16px;
  margin-bottom: 32px;
  height: 90px;
}
.blog-title1 {
  font-size: 20px;
  padding-top: 12px;
  height: 75px !important;
  margin-bottom: 24px !important;
}
.blog-post-body {
  font-size: 16px;
  line-height: 33px;
  letter-spacing: 0.72px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 16px;
  width: 100%;
  height: 132px;
}
.bp-body1 {
  /* font-size: 14px !important; */
  line-height: 27px !important;
  letter-spacing: 0.59px !important;
  margin-bottom: 14px !important;
}
.readMore {
  display: inline-block;
  background: linear-gradient(
    to right,
    #d4f36b 0%,
    #d4f36b 38%,
    #a47cf3 76%,
    #683fea 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.rdm {
  font-size: 14px !important;
}
.b-postImg {
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-radius: 4px;
}
.b-postImg1 {
  height: 170px !important;
}

/* ///////////////////////////////////////////////////////////// */
/* ////////////////////blog post screen ////////////////////////*/
/* /////////////////////////////////////////////////////////// */
.blogPostSection {
  width: 100%;
  /* height: 100vh; */
  background: #0b0b0b;
}
.blog-post-nav {
  width: 100%;
  background: #0b0b0b;
  padding-bottom: 48px;
}
.blog-post-topper {
  width: 100%;
  height: 176px;
}
.bpb-section {
  width: 100%;
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.bpb-inner-wrap {
  width: 65%;
  display: flex;
  flex-direction: column;
  /* background: blue; */
}
.title-p {
  font-size: 64px;
  font-family: "ClashGrotesk-Semibold" !important;
  letter-spacing: 0%;
  font-weight: 600 !important;
  color: #fff;
}
.bookmark-card {
  width: 100%;
  height: 104px;
  border-image: linear-gradient(
      to right,
      #d4f36b 0%,
      #d4f36b 38%,
      #a47cf3 76%,
      #683fea 100%
    )
    30;
  border-width: 1px;
  border-style: solid;
  border-right: none;
  border-left: none;
  margin-top: 32px;
  margin-bottom: 48px;
  display: flex;
  align-items: center;
  position: relative;
}
.tx-logo {
  width: 64px;
  height: 64px;
  margin-right: 16px;
}
.st-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.st-wrap p {
  font-size: 20px;
  font-family: "ClashGrotesk-Medium";
  font-weight: 500;
  color: #ffffffb3;
  letter-spacing: 0%;
}
.st-wrap p:first-child {
  margin-bottom: 12px;
}
.text-cat {
  color: #fff;
}
.bookmark-share {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}
.bookmark-share button {
  background: none;
  border: none;
  cursor: pointer;
}
.book-share-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.book-share-btns button {
  width: 157px;
  height: 53px;
  border-radius: 100px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #ffffffb3;
  background: #1a1a1a;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.book-share-btns button:first-child {
  margin-right: 12.5px;
}
.book-share-btns button svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.bmk-btn1 {
  margin-right: 24px;
}
.share-drop {
  width: 183px;
  height: 63px;
  /* background: red; */
  position: absolute;
  margin-top: 45px;
  margin-left: -78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.copyLink {
  width: 100%;
  height: 54px;
  background: #000000;
  border: 2px solid #383838;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.copyLink img {
  margin-left: 14px;
  margin-right: 6px;
}
.copyLink p {
  color: #fff;
}
.post-text {
  color: #fff;
  letter-spacing: 0.72px;
  font-size: 18px;
  line-height: 33px;
  margin-bottom: 48px;
  font-weight: 400;
}
.post-img {
  width: 100%;
  height: 416px;
  border-radius: 4px;
  margin-bottom: 48px;
}
.text-mgr {
  margin-bottom: 170px !important;
}
.subscription-section {
  position: relative;
}
.blend {
  width: 100%;
  background: linear-gradient(
    to bottom,
    #0b0b0b00 0%,
    #0b0b0b40 25%,
    #0b0b0bf2 63%,
    #0b0b0b 100%
  );
  height: 241px;
  /* z-index: 100px; */
  position: absolute;
  margin-top: -241px;
  z-index: 100;
}
.sub-modal {
  width: 100%;
  /* height: 20vh; */
  /* background: red; */
  display: flex;
  align-items: center;
  flex-direction: column;
}
.sub-text-head {
  font-size: 44px;
  color: #fff;
  font-weight: 600;
  font-family: "ClashGrotesk-Semibold" !important;
  letter-spacing: 0%;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
}
.sub-text-p {
  color: #fff;
  letter-spacing: 0%;
  font-size: 18px;
  margin-bottom: 48px;
  font-weight: 500;
  font-family: "ClashGrotesk-Medium";
  max-width: 450px;
  text-align: center;
}
.sub-list-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background: red; */
  width: 438px;
  margin-bottom: 24px;
}
.sub-list-wrap img {
  margin-right: 10px;
}
.sub-list {
  text-align: left;
  color: #fff;
  letter-spacing: 0%;
  font-size: 20px;
  font-weight: 500;
  font-family: "ClashGrotesk-Medium";
  /* max-width: 450px; */
}
.sub-btn {
  height: 64px;
  border-radius: 100px;
  margin-bottom: 64px;
  margin-top: 40px;
}
.sub-btn p {
  font-size: 20px !important;
  line-height: 33px;
  letter-spacing: 0.72px !important;
  font-weight: 600 !important;
}
.sub-btn svg {
  margin-right: 4px !important;
}
/* //////////////// ///////////////////////////////////////////////////////////////////////////////////*/
/* ////////////// ////////////////////////////////////////////////////////////////////////////////////*/
/* ///////////////////////////// Login create account Section ///////////////////////////////////////*/
/* ///////////////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////////////// */

.login-section {
  width: 100%;
  height: 110vh;
  background: #0b0b0b;
}
.register-section {
  height: auto !important;
}
.login-nav {
  width: 100%;
  /* background: red; */
  background: #0b0b0b;
  padding-top: 48px;
  position: fixed;
  z-index: 100;
}
.login-nav svg {
  margin-left: 64px;
}
.login-topper {
  width: 100%;
  height: 140px;
}
.login-body {
  width: 100%;
  /* background: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.l-head-text {
  font-size: 44px;
  letter-spacing: 0%;
  color: #fff;
  font-family: "ClashGrotesk-Semibold";
  font-weight: 600;
  margin-bottom: 8px;
}
.l-body-text {
  color: #e8e8e8cc;
  max-width: 601px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
}
.login-wrap form {
  width: 561px;
  margin-top: 64px;
}
.input-cont1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.input_label1 {
  color: #fff;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.15px;
  font-weight: 500;
  margin-bottom: 12px;
}
.custom-input {
  width: 100%;
  height: 64px;
  background: #ffffff1a;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.input1 {
  width: 90%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  padding-left: 24.5px;
  box-sizing: border-box;
  color: #fff;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.15px;
  font-weight: 500;
}
input-wrap1 {
  width: 100%;
  margin-bottom: 40px;
}
.input-wrap2 {
  width: 100%;
  margin-bottom: 32px;
}
.eye-icon {
  margin-right: 16px;
}
.signIn-btn {
  width: 100%;
  height: 64px;
  background: #fff;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.72px;
  line-height: 26px;
  cursor: pointer;
}
.check-cont {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background: red; */
  margin-top: 16px;
}
.check-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}
.check-content img {
  margin-right: 8px;
}
.check-content p {
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0%;
  font-family: "Lato", sans-serif;
  color: #ffffff99;
}

/* //////////////// ///////////////////////////////////////////////////////////////////////////////////*/
/* ////////////// ////////////////////////////////////////////////////////////////////////////////////*/
/* ///////////////////////////////////// subscription Section ///////////////////////////////////////*/
/* ///////////////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////////////// */

.sub-topper {
  width: 100%;
  height: 120px;
}
.sub-content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.sth {
  width: 70%;
  padding-top: 0;
  padding-bottom: 47.93px;
  /* background: red; */
  align-self: center;
  justify-self: center;
}
.nav-switch-cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-switch {
  width: 491px;
  height: 72px;
  border-radius: 8px;
  background: #1e1e1e;
  display: flex;
  /* background-color: red; */
}
.page {
  width: 50%;
  height: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  cursor: pointer;
}
.page p {
  color: #ffffff99;
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0px;
  font-weight: 600;
  transition: color 0.3s;
}
.page2 {
  background: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  flex-direction: column;
  cursor: pointer;
}
.page2 p {
  color: #ffffff99;
  text-align: center;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0px;
  font-weight: 600;
  transition: color 0.3s;
}
.page2 p:last-child {
  font-size: 14px;
  font-weight: 500 !important;
  /* margin-top: 4px; */
}
.page-active {
  background: #d3f26a0f;
  border: 2px solid #d4f36b;
  box-sizing: border-box;
}
.page-active p {
  color: #fff;
}
.active-page-cont {
  width: 100%;
  /* background: rgb(135, 155, 255); */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.sub-page {
  width: 561px;
  /* height: 400px; */
  /* background: red; */
  margin-top: 48.07px;
  padding-top: 20px;
  /* padding-left: 24px; */
  padding-bottom: 20px;
  border-radius: 16px;
  border: 1.5px solid #5d5d5d;
  box-sizing: border-box;
  margin-bottom: 100px;
}
.modal-header {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  /* background: red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1.5px solid #5d5d5d;
}
.modal-header button {
  width: 114px;
  height: 46px;
  border-radius: 100px;
  border: none;
  background: #d4f36b;
  font-size: 18px;
  font-weight: 500;
  font-family: "ClashGrotesk-Medium";
  letter-spacing: 0%;
  cursor: pointer;
}
.mh-text-cont {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}
.mh-text-cont img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.star-wrap {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 10px;
}
.star-wrap p {
  font-size: 14px;
  color: #fdd835;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
}
.price,
.old-price {
  font-size: 24px;
  color: #fff;
  font-family: "ClashGrotesk-Semibold";
  margin-bottom: 20px;
  letter-spacing: 0%;
}
.old-price {
  color: #ffffff99 !important;
  text-decoration: line-through;
  margin-right: 8px;
}
.modal-body {
  margin-left: 24px;
  margin-top: 21px;
}
.sub-ben {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

.sub-ben p {
  font-size: 18px;
  color: #fff;
  font-family: "ClashGrotesk-Medium";
  letter-spacing: 0%;
  margin-left: 10px;
}

/* ////////////////////////////////// */
/* //////////////payment//////////// */
/* //////////////////////////////// */

.pay {
  padding-top: 0;
  padding-bottom: 32px;
}
.change-plan {
  color: #d4f36b;
  font-size: 18px;
  font-family: "ClashGrotesk-Medium";
  letter-spacing: 0%;
}
.pay-dropdown {
  background: none;
  border: none;
  margin-left: 24px;
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
  font-size: 18px;
  font-family: "ClashGrotesk-Medium";
}
.py-sub-benefit {
  font-size: 16px !important;
}
.pay-plan {
  margin-bottom: 64px !important;
}
.pcd-cont {
  width: 561px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.pcd-cont p {
  color: #fff;
  font-size: 24px;
  font-family: "ClashGrotesk-Semibold";
  font-weight: 600;
  letter-spacing: 0%;
  margin-bottom: 16px;
}
.py-inner-wrap {
  width: 561px;
  /* background: red; */
}
.py-modal-header {
  margin-bottom: 39.7px;
}
.brand-logo {
  margin-right: 16px;
}
.py-inputs-cont {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
}
.cvv-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cvv-input-cont1 {
  width: 47%;
  margin-top: 24px;
  /* background: red; */
}
.pay-btn {
  width: 100%;
  background: #fff;
  border: none;
  border-radius: 100px;
  height: 56px;
  margin-top: 32px;
  margin-bottom: 24px;
  font-weight: 600 !important;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: 0.72px;
  cursor: pointer;
}
.pay-btn2 {
  width: 100%;
  background: #fff;
  border: none;
  border-radius: 100px;
  height: 56px;
  margin-top: 32px;
  margin-bottom: 24px;
  font-weight: 600 !important;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: 0.72px;
  cursor: pointer;
}
.py-method {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.15px;
  line-height: 19px;
}
.py-method span {
  font-weight: 500;
  color: #d3f26a;
}

/* ////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////// */
/* //////////////////////pop modal///////////////// */
/* ////////////////////////////////////////////// */

.pop-modal {
  width: 100%;
  height: 100vh;
  background: #0b0b0b;
  position: absolute;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  position: fixed;
}
.pop-content {
  width: 387px;
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pop-content img {
  margin-bottom: 40px;
}
.pop-text1,
.pop-text2 {
  text-align: center;
}
.pop-text1 {
  color: #fff;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.6px;
  font-family: "Libre Franklin", sans-serif !important;
  margin-bottom: 8px;
}
.pop-text2 {
  font-size: 16px;
  line-height: 24px;
  left: 0px;
  color: #e8e8e8cc;
  margin-bottom: 40px;
  font-weight: 400;
  width: 327px;
}
.pop-text2 span {
  font-size: 16px;
  font-weight: 600;
}
.pop-btn {
  width: 100%;
  height: 56px;
  background: #fff;
  border: none;
  color: #000;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.72px;
  border-radius: 100px;
  line-height: 33px;
  cursor: pointer;
}

/* /////////////////////////////////////////// */
/* ////////////////////////////////////////// */
/* //////////////BlogDashboard///////////// */
/* ///////////////////////////////////////// */
/* //////////////////////////////////////// */

.dashboard-layout {
  width: 100%;
  display: flex;
}
.left-layout {
  width: 18%;
  /* background: red; */
  height: 100vh;
  position: fixed;
}
.left-spacer {
  width: 18%;
  /* background: red; */
  height: 100vh;
  /* position: fixed; */
}
.body-layout {
  width: 82%;
  /* height: 100vh; */
  background: #0b0b0b;
  /* overflow-y: auto; */
}
.profile-nav-cont {
  width: 82vw;
  height: 69px;
  background: gray;
  position: fixed;
  z-index: 20;
  /* box-sizing: border-box; */
}
.dash-pc{
  height: 10vh !important;
  background: red;
}
.sec-body2{
  height: 90vh;
  background: blue;
}


.m-pro-nav {
  width: 100%;
  background: #0b0b0b;
  height: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px solid #2b2b2b;
  border-left: none;
  border-right: none;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.share-noti {
  position: absolute;
  right: 16px;
}

/* ////////////////////////side bar///////////////////////// */

.side-cont {
  width: 100%;
  height: 100%;
  background: #0b0b0b;
  border-right: 1px solid #2b2b2b;
  /* position: fixed; */
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.side-logo-cont {
  padding-top: 24px;
  padding-left: 32px;
  margin-bottom: 58px;
}
.sideBar-ul1 {
  width: 100%;
  /* background: red; */
  box-sizing: border-box;
  padding-left: 14px;
  padding-right: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.sideBar-ul1 li {
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.side-bar-links {
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff99;
  line-height: 20px;
  letter-spacing: 0%;
  display: flex;
  align-items: center;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 10px;
  margin-bottom: 18px;
}
.side-bar-links svg {
  margin-right: 10px;
}
.active-link {
  background: #161126;
  color: #b981f1;
}
.last-sec1 {
  margin-bottom: 56px !important;
}
.logOut-btn {
  background: none;
  border: none;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 18px;
  padding-left: 18px;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff99;
  line-height: 20px;
  letter-spacing: 0%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.logOut-btn img {
  margin-right: 10px;
}

/* ////////////////////////profile nav///////////////////////// */
.profile-nav {
  width: 100%;
  height: 100%;
  background: #0b0b0b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-bottom: 1px solid #2b2b2b;
}
.user-name {
  font-size: 20px;
  letter-spacing: 0%;
  color: #fff;
  font-family: "ClashGrotesk-Medium";
  font-weight: 500;
  margin-left: 48px;
}
.rightHand-cont {
  height: 100%;
  /* background: red; */
  display: flex;
  align-items: center;
  /* position: absolute;
  right: 0; */
  margin-right: 48px;
}
.search-cont {
  margin-right: 32px;
}
.notification-cont {
  margin-right: 56px;
  position: relative;
}
.notification-drop {
  width: 444px;
  height: 380px;
  background: #0b0b0b;
  position: absolute;
  margin-left: -27vw;
  margin-top: 25px;
  border-radius: 10px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px solid #333333;
  transition: opacity 0.3s;
}
.notification-h {
  width: 100%;
  background: #0b0b0b;
  height: 51px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 15px;
  color: #808080;
  display: flex;
  align-items: center;
  font-family: "ClashGrotesk-Regular";
  font-weight: 400;
  padding-left: 18px;
  box-sizing: border-box;
  border-bottom: 1px solid #333333;
}
.notification-body {
  width: 100%;
  height: 310px;
  /* background: red; */
  overflow: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.notification-item {
  width: 90%;
  height: 58px;
  flex-shrink: 0;
  border-bottom: 1px solid #2b2b2b;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
}
.notification-item img {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
.text-date-wrap p {
  font-size: 16px;
  color: #fff;
  line-height: 28px;
  letter-spacing: 0%;
  font-weight: 400;
}
.text-date-wrap p:last-child {
  font-size: 14px;
  line-height: 20px;
  color: #ffffff80;
}
.card-empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.card-empty img {
  margin-bottom: 37px;
}
.card-empty h4 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  line-height: 24px;
  letter-spacing: 0.6px;
  margin-bottom: 8px;
}
.card-empty p {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #e8e8e8cc;
  letter-spacing: 0px;
  font-weight: 400;
}
.profile-wrap {
  display: flex;
  align-items: center;
  /* background: red; */
  position: relative;
}
.profile-drop {
  color: #ffffff;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
  cursor: pointer;
}
.profile-drop img {
  margin-left: 16px;
  object-fit: cover;
}
.profile-nav-topper {
  width: 100%;
  height: 117px;
}
.main-body {
  width: 100%;
  margin-top: 69px;
  background-image: url("../assests/images/dbg.webp");
  background-position: center center;
  background-position-x: right;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  /* height: 90vh;
  overflow-y: scroll; */
}

.empty-sec {
  width: 100%;
  height: 70vh;
  background: #0b0b0b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty-sec img {
  margin-bottom: 24px;
}
.bd-text {
  margin-top: 48px;
  display: inline-block;
  text-align: center;
}
.db-tab-link-cont {
  width: 100%;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #151515;
  margin-bottom: 80px;
}
.db-tab-inner {
  display: flex;
  max-width: 90%;
  align-items: center;
  justify-content: flex-start;
  /* overflow-x: scroll; */
}
/* .db-tab-inner::-webkit-scrollbar {
  display: none;
} */
.db-content-card {
  width: 286.67px;
}
.ad-main-body {
  background-image: url("../assests/images/dbg1.webp") !important;
  height: 90vh;
  overflow-y: hidden !important;
  justify-content: flex-start !important;
}
.book-btn {
  height: 64px !important;
}
.book-btn p {
  font-size: 20px;
}
.book-btn svg {
  margin-right: 5px !important;
}
.ad-text {
  margin-bottom: 72px !important;
}
.ab-text {
  margin-bottom: 40px !important;
}
.ab-text-sec {
  width: 764px;
  /* background-color: red; */
}
.ab-text-sec p {
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 33px;
  letter-spacing: 0.72px;
  font-weight: 400;
  margin-bottom: 24px;
}
.hs-text-sec {
  width: 660px;
  /* background-color: red; */
  margin-bottom: 80px;
}
.hs-text-sec p {
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.72px;
  font-weight: 400;
  margin-bottom: 24px;
}
.hs-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hs-modal .hs-modal-items:first-child {
  margin-right: 24.28px;
}
.hs-modal-items {
  width: 430.86px;
}
.item-card {
  width: 100%;
  height: 227.57px;
  background: #141414;
  border-radius: 18.21px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hs-btn {
  width: 100%;
  height: 60.68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 75.86px;
  border: none;
  padding-left: 24px;
  padding-right: 9px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}
.faq-cont2 {
  width: 70%;
}
.fq-text {
  margin-bottom: 64px !important;
}
.logoutPop {
  width: 100vw;
  height: 100vh;
  background: #000000b3;
  position: fixed;
  top: 0;
  z-index: 9999999999;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1px);
}
.logout-card {
  width: 546px;
  height: 275px;
  border-radius: 16px;
  background: #0b0b0b;
  border: 1px solid #5d5d5d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.logout-card img {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
.log-text1 {
  color: #fff;
  font-size: 30px;
  letter-spacing: 1%;
  font-family: "ClashGrotesk-Medium";
  font-weight: 500;
  margin-bottom: 8px;
}
.log-text2 {
  color: #808080;
  font-size: 16px;
  letter-spacing: 2%;
  line-height: 21px;
  font-weight: 400;
  margin-bottom: 53px;
  width: 372px;
  text-align: center;
}
.log-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.log-control-btn {
  width: 180.52px;
  height: 52px;
  border-radius: 100px;
  border: none;
  background: #1e1e1e;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.72px;
  cursor: pointer;
}
.log-control-btn:first-child {
  margin-right: 12px;
  /* border: 1px solid #ffffff80; */
  box-sizing: border-box;
  color: #fff;
}
.ppr-card {
  width: 183px;
  height: 78px;
  border-radius: 4px;
  background: #000000;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px solid #262626;
  position: absolute;
  top: 60px;
  padding: 10px;
}
.ppr-btn {
  border: none;
  background: none;
  font-size: 16px;
  letter-spacing: 0%;
  line-height: 21px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 16px;
  cursor: pointer;
}
.ppr-btn:last-child {
  margin-bottom: 0px;
}
.ppr-sec {
  background-image: none !important;
  height: auto;
}
.pro-fp {
  font-size: 40px;
  letter-spacing: 2%;
  color: #fff;
  margin-top: 56px;
  margin-bottom: 40px;
  font-family: "ClashGrotesk-Medium";
}
.ppr-img-frame {
  width: 561px;
  height: 140px;
  border-radius: 8px;
  background: #151515;
  display: flex;
  align-items: center;
  padding: 24px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.ppr-img-frame img {
  width: 92px;
  height: 92px;
  border-radius: 284px;
  object-fit: cover;
}
.up-wrap {
  margin-left: 24px;
}
.up-wrap p {
  color: #808080;
  font-size: 16px;
  font-family: "ClashGrotesk-Medium";
  letter-spacing: 0.15px;
  font-weight: 500;
  margin-bottom: 20px;
}
.up-btn-wrap {
  display: flex;
  align-items: center;
  /* background: red; */
}
.up-btn {
  background: #222222;
  border: 1px solid #222222;
  height: 40px;
  width: 151px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.05px;
  font-weight: 500;
  cursor: pointer;
}
/* .up-btn:last-child {
  color: #ffffff4d;
  margin-left: 12px;
} */
.up-btn svg {
  margin-right: 8px;
}
.ppr-form {
  margin-top: 48px;
  width: 561px;
}
.cp-cont {
  margin-top: 12px;
}
.cp {
  text-decoration: none;
  padding-top: 12px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #a47cf3;
  cursor: pointer;
  background: none;
  border: none;
}
.cp-btn,
.cans-btn {
  width: 100% !important;
  height: 64px !important;
}

.user-sup-p {
  color: #e8e8e8cc;
  margin-top: 8px;
  width: 440px;
  text-align: center;
  /* background: red; */
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  margin-bottom: 64px;
}
.asc-wrap {
  width: 561px;
}
.next-py {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  font-weight: 400;
  margin-bottom: 20px;
}
.sub-page1 {
  margin-bottom: 40px !important;
}
.cancel-sub {
  width: 100%;
  height: 64px;
  border: 1.5px solid #5d5d5d;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background: none;
  border-radius: 16px;
  text-align: left;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.15px;
  padding-left: 24px;
  font-weight: 500;
  cursor: pointer;
}
.card-info {
  font-size: 18px;
}
.ex-date {
  margin-top: 8px !important;
  font-family: "Red Hat Text", sans-serif !important;
  font-weight: 500 !important;
}
.ex-text {
  /* width: 561px; */
  color: #e8e8e8cc;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
}
.rcdr {
  display: inline-block;
  background: linear-gradient(
    to right,
    #d4f36b 0%,
    #d4f36b 38%,
    #a47cf3 76%,
    #683fea 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px !important;
  font-weight: 600 !important;
  font-weight: 600;
  font-family: "ClashGrotesk-Semibold";
  width: 400px;
  padding-top: 130px;
}
.rcdr-top {
  /* margin-bottom: 40px; */
  padding-top: 56px;
}
.recommend{
  margin-bottom: 118px;
}
.blog-menu {
  width: 100%;
  height: 100vh;
  background: #0b0b0b;
  position: absolute;
  top: 0;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blog-menu-nav {
  width: 90%;
  /* background: red; */
  margin-top: 32px;
  margin-bottom: 64px;
  display: flex;
  justify-content: space-between;
}
.blog-menu-body {
  width: 90%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* background: red; */
}
.menu-links {
  font-size: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 600;
  margin-bottom: 24px;
}
.menu-links svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.active-menu-link {
  color: #b981f1;
}
.menu-logout-btn {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  background: none;
  border: none;
}
.menu-logout-btn img {
  margin-right: 10px;
}
.acc-drop-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 56px;
}
.acc-drop-content button {
  width: 100px;
  color: #fff;
  background: none;
}
.mppr-btn {
  display: flex;
  margin-bottom: 24px;
  font-weight: 400;
  letter-spacing: 0.2px;
}
.mNoti-section {
  width: 100%;
  height: auto;
  background: #0b0b0b;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile-noti-nav {
  width: 100%;
  height: 174px;
  position: fixed;
  background: #0b0b0b;
  display: flex;
  align-items: center;
}
.mobile-noti-nav-bdr {
  border-bottom: 1px solid #2b2b2b;
}
.mobile-noti-body {
  width: 90%;
  /* background: red; */
  min-height: 85vh;
}
.noti-topper {
  width: 100%;
  height: 174px;
}
.nt-head {
  font-size: 28px;
  color: #fff;
  letter-spacing: 0%;
  font-family: "ClashGrotesk-Medium";
  margin-top: 76px;
  margin-bottom: 48px;
  margin-left: 24px;
  /* margin-top: 58px; */
}
.noti-empty {
  width: 100%;
  height: 50vh;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.noti-item {
  width: 100% !important;
  height: 90px;
  align-items: flex-start;
  margin-bottom: 24px !important;
}
.noti-item img {
  margin-top: 5px;
}
.noti-tdw {
  /* background: red; */
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}
.noti-tdw p {
  line-height: 24px !important;
}
.noti-tdw p:last-child {
  margin-top: 8px;
}
.sub-profile-nav {
  width: 100%;
  height: 100%;
  background: #0b0b0b;
}
.ms-section {
  width: 100%;
  height: auto;
  background: #0b0b0b;
}
.ms-nav {
  width: 100%;
  height: 65px;
  position: fixed;
  padding-top: 16px;
  background: #0b0b0b;
}
.s-inputCont {
  width: 75%;
  height: 44px;
  background: #ffffff1a;
  border-radius: 10px;
  margin-right: 3vw;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.s-inputCont input {
  height: 90%;
  width: 80%;
  margin-left: 16px;
  background: none;
  border: none;
  outline: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.s-inputCont input::placeholder {
  color: #b6b6b6;
}
.s-inputCont button {
  background: none;
  border: none;
}
.ms-body {
  width: 100%;
  min-height: 100vh;
  background: #0b0b0b;
  /* padding-top: 65px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  box-sizing: border-box;
}
.null-search {
  width: 100%;
  height: 60vh;
  align-items: center;
  /* background: red; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.null-search img {
  margin-bottom: 45px;
}
.null-search h4 {
  align-self: center !important;
  color: #fff;
  font-size: 28px;
  font-family: "ClashGrotesk-Semibold";
  letter-spacing: 0.6px;
  margin-bottom: 8px;
  text-align: center !important;
}
.null-search p {
  font-size: 15px;
  color: #e8e8e8cc;
  width: 320px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
}
.s-content {
  border: none !important;
}
.regisLink {
  color: #e8e8e8cc;
  text-align: center;
  margin-top: 48px;
  font-size: 18px;
  letter-spacing: 0.72px;
  font-weight: 400;
}
.regisLink span {
  color: #d4f36b;
  font-weight: 600;
}
.input-error {
  border: 1px solid #ba2543;
}
.error-text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #ba2543;
  margin-top: 10px;
}

.otp-cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: red; */
  margin-bottom: 40px;
}
.otp-input {
  width: 72px;
  height: 72px;
  background: rgba(255, 255, 255, 0.25);
  margin-right: 16px;
  border-radius: 4px;
  border: 1.5px solid transparent;
}
.otp-input input {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  line-height: 22px;
}
.otp-form {
  margin-top: 100px !important;
}
.otp-form p {
  color: #e8e8e8cc;
  font-size: 16px;
  text-align: center;
  margin-bottom: 24px;
}
.userMail {
  color: #478dce;
  font-weight: 500;
}
.otp-btn-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.otp-btn {
  width: 385px !important;
}
.resend-timer {
  font-size: 16px !important;
  letter-spacing: 0%;
  font-weight: 400;
  margin-bottom: 80px !important;
}
.seconds-timer {
  font-weight: 500;
  color: #fff !important;
}
.rsnd {
  margin-bottom: 80px !important;
  cursor: pointer;
}
.pagination-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  margin-bottom: 32px;
}
.pagination-arrow {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100px;
  border: none;
  margin-right: 16px;
  background-color: #202020;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-arrow:first-child svg {
  transform: rotate(-180deg);
}
.pagination-arrow:last-child {
  margin-right: 0;
}
.pagination-button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100px;
  border: none;
  background-color: #202020;
  color: #ffffff4d;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 16px;
}
.pagination-button:hover {
  background-color: #fff;
  color: #000;
}
.p-dots{
  color: #ffffff4d;
  font-size:2rem;
  margin-right: 16px;
}
.active-pagination {
  background-color: #fff;
  color: #000;
}

.searchField {
  width: 297px;
  height: 44px;
  background: #ffffff1a;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  position: relative;
}
.searchField input {
  margin-left: 16px;
  background: none;
  border: none;
  height: 80%;
  outline: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.txt-error {
  border: 1px solid #ba2543 !important;
}


.currentPlan{
  width: 135px !important;
}