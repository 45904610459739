@media screen and (max-width: 767px) {
  .bodySection {
    background-image: url("../assests/images/mobilebg.png");
    background-position: top;
    background-size: cover;
    background-attachment: scroll;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
  }
  .section3 {
    /* background: red; */
    background-image: none;
  }
  .section2 {
    background: none;
    background-image: none;
  }
  .tz-project-section {
    background: none;
    background-image: none;
  }
  .rept {
    background: none;
  }
  .reserve-cont {
    background: none;
  }
  .factory-faq {
    background: none;
  }
  .navbar {
    /* background: red; */
    position: fixed;
  }
  .navbar-links {
    display: none;
  }
  .login-signup {
    display: none;
  }
  .navBar-btn {
    margin-right: 0;
  }
  .con-btn {
    width: 80vw;
    /* margin-left: 40px; */
  }
  .container-fluid {
    /* background: blue; */
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    padding-left: 16px;
  }
  .navbar-brand {
    margin-left: 0;
  }
  .menu-toggle {
    display: flex;
  }
  .gradient-text {
    font-size: 50px;
    text-align: center;
    margin-top: 64px;
    font-weight: 500;
    max-width: 320px;
  }
  .text-alt {
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 36px;
  }
  .mt-text {
    max-width: 327px;
    font-size: 15px;
    letter-spacing: 0.4px;
    line-height: 26px;
  }
  .innerTextSection {
    align-items: center;
    justify-content: center;
  }
  .bg-img-cont {
    background-image: none;
    background-size: cover;
    width: 100%;
    height: auto;
    margin-top: 36px;
    flex-direction: column;
    align-items: center;
  }
  .store-btn-wrap {
    flex-direction: column;
  }
  .app-store-btn {
    margin-right: 0;
    margin-bottom: 14px;
  }
  .section2 {
    /* background: red; */
  }
  .gradient-bg {
    display: none;
  }
  .himg {
    width: 100%;
  }
  .g-text {
    font-size: 36px;
    margin-top: 120px;
    max-width: 327px;
    text-align: center;
    letter-spacing: 2%;
    font-weight: 500;
    /* background-color: red; */
    /* display: block; */
  }
  .text-alt2 {
    font-size: 36px;
    max-width: 239px;
    font-weight: 500;
    margin-top: 2px;
    text-align: center;
    margin-bottom: 16px;
    /* background-color: aqua; */
  }
  .modal-section {
    flex-direction: column;
    align-items: center;
  }
  .wrap1 {
    margin-right: 0;
    margin-bottom: 32px;
  }
  .card-modal {
    max-width: 327px;
    height: auto;
    border-radius: 16px;
  }
  .card-modal svg {
    width: 30.24px;
    height: 30.24px;
    margin-bottom: 20.76px;
  }
  .btn-sec {
    width: 327px;
    height: 56px;
  }
  .btn-sec p {
    font-size: 16px;
    margin-left: 24px;
  }
  .btn-sec svg {
    width: 42.05px;
    height: 42.05px;
  }
  .modal-hText {
    font-size: 24px;
  }
  .modal-pText {
    margin-top: 4px;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    padding-bottom: 16px;
    /* margin-bottom: 16px; */
  }
  .g-text2 {
    font-size: 36px;
  }
  .mg-top-220 {
    margin-top: 120px;
  }
  .content-section {
    flex-direction: column;
  }
  .store-frame {
    width: 327px;
    height: 381px;
  }
  .frameText {
    font-size: 24px;
    margin-top: 13.8px;
    margin-bottom: 14.2px;
  }
  .st-btn {
    width: 142.22px;
    height: 47.55px;
    margin-bottom: 10px;
  }
  .st-btn img {
    width: 18px;
  }
  .st-btn p {
    margin-left: 8px;
  }
  .content-frame {
    max-width: 327px;
    height: auto;
    margin-left: 0px;
    margin-top: 24px;
    /* border-radius: 12px; */
  }
  .card-frame {
    border-radius: 12px;
    height: 149px;
    margin-bottom: 24px;
  }
  .card-frame-header {
    margin-left: 16.42px;
    margin-top: 18.12px;
  }
  .card-frame-header img {
    width: 60.58px;
    height: 30.29px;
  }
  .card-frame-header p {
    font-size: 24px;
    margin-left: 10px;
  }
  .card-frame-body-text {
    margin-left: 16.42px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin-right: 15.58px;
  }
  .tkc-section {
    flex-direction: column;
    margin-top: 32px;
  }
  .tokenized-content {
    width: 326px;
    /* background: red; */
    flex-direction: row;
    height: 76.75px;
    justify-content: space-between;
    margin-bottom: 32px;
  }
  .tokenized-content img {
    width: 98px;
    height: 100%;
    border-radius: 12px;
    margin-right: 16px;
    padding: 0;
  }
  .activeIndex {
    padding: 0;
    border-radius: 12px;
    margin: 0;
    border: 3px solid #fff;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .enlargeFrame {
    flex-direction: column;
    margin-left: 0;
  }
  .img-frame img {
    max-width: 327px;
    /* height: 194px; */
  }
  .text-frame {
    width: 327px;
    height: 179px;
    margin-left: 0;
    margin-top: 20.55px;
  }
  .text-frame::before {
    border-radius: 16.69px;
    padding: 1.39px;
  }
  .tf-p1 {
    font-size: 18px;
    margin-bottom: 12.59px;
  }
  .tf-p2 {
    line-height: 22px;
    font-size: 14px;
  }
  .text-frame-wrap {
    margin-left: 20.25px;
    margin-right: 15.75px;
  }
  .curvedArrow {
    margin-top: 150px;
    transform: rotate(20deg);
    right: 34px;
    /* position: relative; */
    z-index: 10;
  }
  .mg-top-200 {
    margin-top: 120px;
  }
  .partners {
    flex-direction: column;
  }
  .partners img:last-child {
    height: 37.47px;
  }
  .mg-bottom-56 {
    margin-bottom: 40.3px;
  }
  .pt-img-first-child {
    margin-right: 0;
    width: 212.21px;
    height: 54.51px;
    margin-bottom: 32px;
  }
  .mg-top-100 {
    margin-top: 100.72px;
  }
  .card-header-row {
    justify-content: center;
  }
  .wg-text {
    text-align: center;
    font-size: 36px;
    max-width: 286px;
    /* margin-bottom: 32px; */
  }
  .testnimonial-cont {
    margin-top: 32px;
    margin-left: 16px;
    overflow-y: hidden;
  }
  .testimonial-cards {
    flex: 0 0 312.99px;
    height: 260px;
    border-radius: 12px;
  }
  .testimonial-cards::before {
    border-radius: 12px;
  }
  .spacer {
    flex: 0 0 7vw; /* Adjust the width of the space before the first card */
    /* background: red; */
  }
  /* .spacer2{
    flex: 0 0 800px !important;
  } */
  .tc-cards {
    flex: 0 0 312.99px;
    height: 260px;
    border-radius: 12px;
  }
  .testCard-inner {
    margin-left: 22.04px;
    margin-top: 22.04px;
    margin-right: 13px;
  }
  .testCard-head img {
    width: 40;
    height: 40;
  }
  .ts-name {
    font-size: 20px;
  }
  .ts-title {
    font-size: 14px;
  }
  .testCard-text {
    margin-left: 11px;
    /* background-color: red; */
    align-items: flex-start;
    display: block;
  }
  .feedback {
    font-size: 14px;
    line-height: 24px;
    flex: 0 0 277px;
    letter-spacing: 0.4px;
  }
  .main-cont {
    flex-direction: column;
    margin-top: 120px;
  }
  .main-cont-text {
    margin-right: 24px;
  }
  .main-cont img {
    width: 100%;
    height: 312.96px;
  }
  .text1 {
    font-size: 16px;
    text-align: center;
    margin-bottom: 8px;
  }
  .text-head {
    font-size: 36px;
    margin-bottom: 48.69px;
  }
  .text-wrap {
    margin-top: 24px;
  }
  .text-wrap p {
    font-size: 15px;
    max-width: 327px;
    line-height: 26px;
  }
  .head-wrap p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 8px;
  }
  .fact-text {
    font-size: 36px;
    max-width: 300px;
  }
  .structure {
    flex-direction: column-reverse;
    margin-top: 32px;
  }
  .structure-cont1 {
    margin-right: 0;
    height: auto;
    margin-top: 48px;
  }
  .fact-text-section {
    margin-bottom: 24px;
  }
  .fact-text-section img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
  .fact-text-wrap p:first-child {
    font-size: 18px;
  }
  .fact-text-wrap p:last-child {
    font-size: 18px;
    max-width: 255px;
    font-size: 15px;
    line-height: 26px;
  }
  .structure-cont2 img {
    /* max-width: 500px; */
    width: 288px;
    /* height: 328px; */
    text-align: center;
    /* margin-bottom: 48px; */
  }
  .mg-FAQ {
    margin-top: 84px;
    margin-bottom: 32px;
  }
  .faq-container {
    width: 327px;
  }
  .faq-item {
    margin-bottom: 16px;
    transition: background-color 0.3s;
    border: 1.5px solid #ffffff4d;
    border-radius: 12px;
    padding-top: 23px;
    padding-bottom: 23px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .faq-question p {
    font-size: 15px;
    width: 247px;
    letter-spacing: 0.72px;
  }
  .faq-question svg {
    width: 24px;
    height: 24px;
  }
  .faq-answer {
    font-size: 14px;
    line-height: 24px;
    width: 261px;
  }
  .g-text2 {
    max-width: 327px;
  }
  .nmp {
    max-width: 327px;
    text-align: center;
    margin-bottom: 16px;
  }
  .btn-cont {
    margin-top: 16px;
  }
  .cta {
    flex-direction: column;
    background-position: center;
    background-image: url("../assests/images/sctabanner.svg");
    max-width: 327px;
    justify-content: center;
  }
  .cta-p {
    font-size: 36px;
    line-height: normal;
    text-align: center;
    padding-left: 0;
    height: auto;
    max-width: 232px;
    margin-top: 24px;
  }
  .cta-content {
    margin-left: 0;
    align-items: center;
    margin-top: 9.5px;
    margin-bottom: 19.5px;
  }
  .cta-content p {
    padding-right: 0;
    width: 279px;
    font-size: 15px;
    line-height: 26px;
    /* letter-spacing: 0.4px; */
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
  }
  /* .mt-text2 {
    margin-bottom: 16px;
  } */
  .footer-logo img {
    width: 114px;
    height: 30px;
    margin-top: 32px;
    /* margin-left: 24px; */
    margin-left: -10px;
  }
  .socials {
    width: 100%;
    margin-top: 40px;
  }
  .card-head {
    margin-top: 48.17px;
  }
  .card-p-mg-top2 {
    margin-top: 16px;
  }
  .card-p-mg-top3 {
    margin-top: 8px;
  }
  .ft-content {
    width: 100%;
    display: flex;
  }
  .card3 {
    margin-left: 0;
  }
  .fbtn-cont {
    width: 90%;
    /* justify-content: space-between; */
    margin-bottom: 40.79px;
  }
  .app-btn {
    margin-right: 10px;
  }
  .bottom-footer {
    width: 100%;
    /* background: red; */
    padding: 0;
    margin: 0;
  }
  .ptl {
    padding-left: 0;
    position: relative;
    left: 0;
  }
  .copyright {
    font-size: 11px;
    font-weight: 200;
    margin-top: 34px;
    line-height: 13px;
    margin-bottom: 24px;
    letter-spacing: 0%;
  }

  .cons-btn {
    display: none;
  }

  /* /////////////////// */
  /* ////////about us .// */
  /* /////////////////// */
  .abt-section {
    background-image: url("../assests/images/abtBg.svg");
  }
  .abt-nav {
    padding-top: 44px;
  }
  .abt-nav svg {
    margin-left: 24px;
  }
  .abt-text-wrap {
    max-width: 85%;
  }
  .abt-g-text {
    font-size: 50px;
    margin-bottom: 24px;
    display: inline-block;
    max-width: 213px;
    /* margin-top: 40px; */
  }
  .abt-p-text {
    width: 100%;
    font-size: 15px !important;
    line-height: 26px !important;
    letter-spacing: 0.4px;
  }
  .policy-g-text {
    max-width: 184px;
  }
  .pp-section {
    background-image: url("../assests/images/policyBg.svg");
    background-position: top;
  }
  .top-space {
    height: 116px;
  }
  .policies-wrap {
    margin-bottom: 24px;
  }
  .policies-wrap p {
    font-size: 18px;
    letter-spacing: 0.4px;
    line-height: 26px;
  }
  .terms-use {
    background-image: url("../assests/images/termsBg.svg");
    background-position: top;
  }
  .terms-p {
    font-size: 15px;
  }
  .t-ptex {
    max-width: 327px;
    font-size: 15px;
  }
  .last-p {
    font-size: 15px;
    line-height: 26px !important;
    letter-spacing: 0.4px !important;
  }
  .aml-text {
    max-width: 100%;
  }
  .aml {
    background-image: url("../assests/images/amlBg.svg");
    background-position: top;
  }
  .ctu-g-text {
    max-width: 100%;
    font-size: 50px;
  }
  .ctu-content {
    /* background: red; */
  }
  .ctu-text-wrap {
    width: 90% !important;
    /* background: red; */
  }
  .input-cont {
    width: 100% !important;
    /* background: red; */
  }
  .send-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input-wrap1 {
    width: 100%;
    margin-bottom: 32px;
    margin-top: 24px;
  }
  .input-wrap {
    width: 90vw;
    margin-bottom: 32px;
  }
  .input {
    width: 100%;
    /* width: 100vw; */
    padding-right: 16px;
    padding-left: 16px;
  }

  .textarea-input {
    /* width: 100vw; */
    padding-right: 16px;
    padding-left: 16px;
  }
  .btn-long {
    width: 100% !important;
    font-size: 16px;
  }
  .ctu-section {
    background-image: url("../assests/images/ctuBg.svg");
    background-position: top;
  }

  /* //////////////////////////////////////////////////////// */
  /* ////////////////////blog section /////////////////////// */
  /* /////////////////////////////////////////////////////// */

  .blog-section {
    overflow-y: hidden;
    background-image: url("../assests/images/bpm.webp");
  }
  .blog-nav {
    flex-direction: column;
    /* background-color: red; */
    padding-top: 16px;
    width: 100vw !important;
    /* position: relative; */
  }
  .blog-nav1 {
    position: relative;
  }
  .blogArrow-back {
    left: 24px;
    top: 16px;
  }
  .blogArrow-back svg {
    height: 32px;
    width: 32px;
  }
  .blog-text {
    margin-top: 72px !important;
    margin-bottom: 16px;
  }
  .blog-topper {
    height: 20px;
    /* background: red; */
  }
  .tab-bar {
    width: 100%;
    justify-content: flex-start;
    position: relative;
    overflow-x: auto;
  }
  .tab-link-cont {
    width: 100% !important;
    /* background: blue; */
    position: relative;
    flex-shrink: 0;
    overflow-x: auto;
    padding-left: 32px;
    padding-right: 32px;
  }
  .b-tab-link-cont {
    padding-right: 30px;
  }
  .tab-links {
    margin-right: 38px;
    flex-shrink: 0;
  }
  .tab-Bar {
    /* background: red; */
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }
  .blog-content {
    height: 75vh;
  }
  .main-blog-section {
    grid-template-columns: 1fr;
  }
  .main-body-b2 {
    background-image: url("../assests/images/bpm2.webp");
    background-position: top !important;
  }
  .blog-content-card {
    border-bottom: 0.8px solid #2b2b2b;
    row-gap: 64px;
    /* background: red; */
  }
  .b-postImg {
    margin-bottom: 32px;
    height: 160px !important;
  }
  .blog-content-card {
    width: 88vw !important;
  }
  .bch-wrap img {
    width: 18px;
    height: 18px;
  }
  .bch-p {
    font-size: 16px;
  }
  .blog-card-header p {
    font-size: 14px;
  }
  .blog-title {
    font-size: 22px;
    margin-bottom: 24px;
  }
  .blog-post-body {
    line-height: 28px;
    letter-spacing: 0.4px;
    margin-bottom: 12px;
  }
  .blog-post-nav {
    width: 100vw !important;
    padding-bottom: 32px !important;
    /* background: green; */
  }
  .blogPostSection {
    width: 100vw;
  }
  .title-p {
    font-size: 32px;
  }
  .blog-post-topper {
    height: 100px;
    /* background: yellow; */
  }
  .bpb-inner-wrap {
    width: 90%;
  }
  .bookmark-card {
    margin-top: 26px;
    align-items: flex-start;
    padding-top: 18px;
    margin-bottom: 26px;
  }
  .tx-logo {
    width: 48px;
    height: 48px;
  }
  .st-wrap {
    width: 200px;
  }
  .st-wrap p {
    font-size: 16px;
  }
  .st-wrap p:last-child {
    margin-top: 6px;
  }
  .text-cat {
    font-size: 16px;
    font-family: "ClashGrotesk-Medium";
    font-weight: 500;
  }
  .bookmark-share {
    display: none;
  }
  .post-text {
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.4px;
    font-weight: 400;
  }

  .post-img {
    width: 100%;
    height: 200px;
    margin-bottom: 32px;
  }
  .text-mgr {
    margin-bottom: 129px;
  }
  .rcdr {
    padding-top: 129px;
    width: 231px;
  }
  .rcdr-top {
    /* margin-bottom: 40px; */
    padding-top: 40px;
    width: 327px;
  }
  .blog-section1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .sub-text-head {
    font-size: 28px;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .subscription-section {
    width: 90vw;
    /* background: red; */
  }
  .sub-list-wrap {
    width: 327px;
  }
  .sub-text-p {
    width: 327px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 40px;
  }
  .sub-list-wrap img {
    width: 20px;
    height: 20px;
  }
  .sub-list-wrap p {
    font-size: 16px;
  }
  .sub-btn p {
    /* width: 100% !important; */
    font-size: 16px !important;
    letter-spacing: 0.42px !important;
    line-height: 19px !important;
  }
  .login-nav {
    padding-top: 24px;
  }
  .login-topper {
    height: 116px;
  }
  .login-nav svg {
    margin-left: 24px;
    width: 32px;
    height: 32px;
  }
  .login-body {
    width: 100%;
  }
  .login-wrap {
    width: 90%;
    align-items: flex-start;
  }
  .l-head-text {
    font-size: 32px;
    font-family: "ClashGrotesk-Medium";
    text-align: left;
  }
  .l-body-text {
    text-align: left;
    font-size: 15px;
    letter-spacing: 0px;
  }
  .login-wrap form {
    width: 100%;
    margin-top: 40px;
  }
  .signIn-btn {
    font-size: 16px;
  }
  .input-wrap1 {
    margin-bottom: 32px;
  }
  .check-cont {
    flex-direction: column;
    align-items: flex-start;
  }
  .check-content {
    margin-bottom: 8px;
  }
  .sth {
    font-size: 30px;
    font-family: "ClashGrotesk-Medium";
    padding-bottom: 32.05;
  }
  .nav-switch {
    width: 100%;
  }
  .page p {
    font-size: 16px;
  }
  .page2 p {
    font-size: 16px;
  }
  .page2 p:last-child {
    font-size: 11px;
    margin-top: 3px;
  }
  .star-wrap {
    margin-bottom: 4px;
  }
  .star-wrap img {
    width: 12px;
    height: 12px;
  }
  .star-wrap P {
    font-size: 12px;
  }
  .price {
    font-size: 16px;
  }
  .old-price {
    font-size: 16px;
  }
  .modal-header {
    padding-left: 13px;
    padding-right: 13px;
  }
  .modal-header button {
    width: 96px;
    height: 38px;
    font-size: 15px;
    margin-top: -20px;
  }
  .modal-body {
    margin-top: 16px;
    margin-left: 13.91px;
    margin-right: 24px;
  }
  .sub-page {
    margin-bottom: 150px;
    margin-top: 32px;
    padding-top: 12px;
    padding-bottom: 16px;
  }
  .sub-ben {
    margin-bottom: 11.59px;
  }
  .sub-ben img {
    width: 18px;
    height: 18px;
  }
  .sub-ben p {
    font-size: 15px;
  }
  .change-plan {
    font-size: 15px;
    margin-top: -20px;
  }
  .pay-dropdown {
    font-size: 15px;
    margin-left: 13.91px;
  }
  .pcd-cont {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .pcd-cont p {
    font-size: 20px;
  }
  .pay-plan {
    margin-bottom: 40px !important;
  }
  .py-inputs-cont {
    padding-left: 13.91px;
    padding-right: 13.91px;
  }
  .py-modal-header {
    margin-bottom: 32px;
  }
  .py-method {
    font-size: 13px;
  }
  .input_label1 {
    font-size: 14px;
  }
  .pay-btn {
    font-size: 16px;
  }
  .pop-content {
    width: 300px;
    margin-top: -50px;
    /* background: red; */
    /* position: relative; */
  }
  .pop-content img {
    width: 76px;
    height: 76px;
  }
  .pop-text1 {
    font-size: 28px;
    margin-bottom: 8px;
  }
  .pop-text2 {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    width: 100%;
  }
  .pop-text2 span {
    font-size: 15px !important;
  }
  .pop-btn {
    position: absolute;
    bottom: 80px;
    width: 327px;
    font-size: 16px;
  }
  .sub-page1 {
    margin-bottom: 24px !important;
  }

  /* /////////////////////////////////////// */
  /* ////////////////////////////////////// */
  /* //////////////blog dashboard////////// */
  /* ////////////////////////////////////// */
  /* /////////////////////////////////////// */
  .dashboard-layout {
    /* overflow-y: hidden; */
    min-height: 125vh;
    background: #0b0b0b;
  }
  .left-layout {
    display: none;
  }
  .left-spacer {
    display: none;
  }
  .body-layout {
    width: 100%;
  }
  .db-tab-link-cont {
    /* overflow-x: auto; */
    margin-bottom: 40px;
  }
  .db-tab-inner {
    width: 100vw !important;
    /* background: blue; */
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    overflow-x: auto;
    padding-left: 24px;
    /* overflow-x: hidden; */
  }

  .db-tab-inner::-webkit-scrollbar {
    display: none;
  }

  .movement-arrow {
    display: none;
  }
  .db-tab-link-cont::-webkit-scrollbar {
    display: none;
  }
  .profile-nav-cont {
    width: 100%;
  }
  .ad-main-body {
    background-image: url("../assests/images/bpm2.webp") !important;
  }
  .ad-main-body2 {
    position: fixed !important;
  }
  .ad-text {
    font-size: 40px;
    margin-bottom: 48px !important;
  }
  .card-modal2 {
    width: 100% !important;
    /* background: red; */
  }
  .card-modal2 svg,
  .card-modal2 p {
    padding-left: 16px;
    margin-left: 0;
    padding-right: 16px;
  }
  .book-btn {
    height: 56px !important;
  }
  .ab-text-sec {
    width: 327px !important;
  }
  .ab-text-sec p {
    text-align: left;
    font-size: 15px;
    letter-spacing: 0.4px;
    line-height: 26px;
  }
  .hs-main-body {
    height: auto !important;
  }
  .hs-text-sec {
    width: 320px;
    margin-bottom: 44px !important;
  }
  .hs-text-sec p {
    font-size: 15px;
    letter-spacing: 0.4px;
    line-height: 26px;
  }
  .hs-modal {
    flex-direction: column;
  }
  .hs-modal-items {
    width: 327px;
    margin-right: 0px !important;
    margin-bottom: 40px;
  }
  .item-card img {
    width: 76px;
    height: 76.59px;
  }
  .ppr-sec {
    /* background: red; */
    /* justify-content: flex-start !important; */
    /* align-items: flex-start !important; */
  }
  .ppr-img-frame {
    width: 327px;
    flex-direction: column;
    height: 176px;
    align-items: center;
    justify-content: center;
    padding-right: 0px;
    padding-left: 0px;
  }
  .ppr-img-frame img {
    width: 56px;
    height: 56px;
  }
  .ppr-form {
    width: 327px;
    /* background: red; */
    margin-top: 40px;
  }
  .chp-fp {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .p-input-wrap1 {
    margin-bottom: 32px !important;
  }
  .pro-fp {
    width: 327px;
    font-size: 28px;
    letter-spacing: 2%;
    color: #fff;
    margin-top: 0px;
    text-align: left;
    margin-bottom: 32px;
    font-family: "ClashGrotesk-Medium";
  }
  .cp {
    text-align: center;
  }
  .up-wrap {
    margin-left: 0px;
  }
  .up-wrap p {
    font-size: 14px;
    letter-spacing: -0.03px;
    margin-top: 18px;
    margin-bottom: 14px;
    text-align: center;
  }
  .up-btn-wrap {
    justify-content: center;
  }
  .up-btn {
    height: 30px;
    font-size: 12px;
    width: 120px;
  }
  .up-btn svg {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
  .custom-input {
    height: 52px !important;
  }
  .eye-icon {
    width: 20px;
    height: 20px;
  }
  .asc-wrap {
    width: 327px;
  }
  .user-sup-p {
    width: 327px;
    text-align: left;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 40px;
  }
  .next-py {
    font-size: 15px !important;
  }
  .logoutPop {
    backdrop-filter: blur(1px);
  }
  .logout-card {
    width: 327px;
    height: 266px;
  }
  .log-text1 {
    font-size: 26px;
    margin-bottom: 12px;
  }
  .log-text2 {
    width: 290px;
    font-size: 15px;
    margin-bottom: 32px;
  }
  .log-control-btn {
    width: 130px;
    height: 50px;
    font-size: 16px;
    letter-spacing: 0.72px;
  }
  .logout-card img {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .cancel-p,
  .cancel-h4 {
    text-align: center;
  }
  .cp-btn {
    /* position: absolute; */
    width: 327px !important;
    /* bottom: 40px; */
  }
  .cans-btn {
    position: absolute;
    width: 327px !important;
    bottom: 40px;
  }
  .cancel-header-t {
    margin-top: 64px;
  }
  .re-text {
    text-align: center;
    width: 200px;
  }
  .otp-input {
    width: 56px;
    height: 56px;
    margin-right: 5px;
  }
  .otp-input input {
    font-size: 22px;
  }
  .otp-form p {
    font-size: 14px;
  }

  .onMenu-p-wrap {
    display: none;
  }
  .readMore {
    font-size: 14px;
    width: 110px;
    letter-spacing: 0.67px;
  }
  .currentPlan {
    width: 110px !important;
  }
  .regisLink {
    font-size: 16px;
  }
  .sub-topper {
    height: 110px;
  }
  .main-body-b2 {
    overflow-y: scroll !important;
  }
}

@media screen and (max-width: 820px) and (min-width: 768px) {
  .bodySection {
    background-image: url("../assests/images/mobilebg.png");
    background-position: top;
    background-size: cover;
    background-attachment: scroll;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
  }
  .section3 {
    /* background: red; */
    background-image: none;
  }
  .section2 {
    background: none;
    background-image: none;
  }
  .tz-project-section {
    background: none;
    background-image: none;
  }
  .rept {
    background: none;
  }
  .reserve-cont {
    background: none;
  }
  .factory-faq {
    background: none;
  }
  .navbar {
    /* background: red; */
    position: fixed;
  }
  .navBar-btn {
    margin-right: 0;
  }
  .navbar-links {
    display: none;
  }
  .login-signup {
    display: none;
  }
  /* .consult-btn{
    display: none;
  } */
  .container-fluid {
    /* background: blue; */
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    padding-left: 16px;
  }
  .navbar-brand {
    margin-left: 0;
  }
  .menu-toggle {
    display: flex;
  }
  .gradient-text {
    font-size: 50px;
    text-align: center;
    margin-top: 64px;
    font-weight: 500;
    max-width: 100vw;
  }
  .text1 {
    margin-top: 32px;
  }
  .text-alt {
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 36px;
  }
  .mt-text {
    max-width: 70vw;
    font-size: 15px;
    letter-spacing: 0.4px;
    line-height: 26px;
  }
  .innerTextSection {
    align-items: center;
    justify-content: center;
  }
  .bg-img-cont {
    /* background-image: none; */
    background-size: cover;
    width: 100%;
    height: 50vw;
    margin-top: 36px;
    flex-direction: column;
    align-items: center;
  }
  .store-btn-wrap {
    /* flex-direction: column; */
    margin-top: -30vw;
  }
  .app-store-btn {
    margin-right: 0;
    margin-bottom: 14px;
  }
  .section2 {
    /* background: red; */
  }
  .gradient-bg {
    display: none;
  }
  .himg {
    width: 100%;
  }
  .g-text {
    /* font-size: 36px; */
    margin-top: 120px;
    max-width: 100vw;
    text-align: center;
    letter-spacing: 2%;
    font-weight: 500;
    /* background-color: red; */
    /* display: block; */
  }
  .flow-btn-cont {
    display: none;
  }
  .text-alt2 {
    font-size: 36px;
    font-weight: 500;
    margin-top: 2px;
    text-align: center;
    margin-bottom: 16px;
    /* background-color: aqua; */
  }
  .modal-section {
    flex-direction: column;
    align-items: center;
  }
  .wrap1 {
    margin-right: 0;
    margin-bottom: 32px;
  }
  .card-modal {
    /* max-width: 327px; */
    /* height: auto; */
  }
  .btn-sec svg {
    width: 42.05px;
    height: 42.05px;
  }
  .modal-hText {
    font-size: 24px;
  }
  .modal-pText {
    margin-top: 4px;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 24px;
    padding-bottom: 16px;
    /* margin-bottom: 16px; */
  }
  .g-text2 {
    font-size: 36px;
  }
  .mg-top-220 {
    margin-top: 120px;
  }
  .content-section {
    flex-direction: column;
  }
  .st-btn {
    width: 142.22px;
    height: 47.55px;
    margin-bottom: 10px;
  }
  .st-btn img {
    width: 18px;
  }
  .st-btn p {
    margin-left: 8px;
  }
  .content-frame {
    margin-left: 0px;
    margin-top: 24px;
    /* border-radius: 12px; */
  }
  .activeIndex {
    padding: 0;
    border-radius: 12px;
    margin: 0;
    border: 3px solid #fff;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .enlargeFrame {
    flex-direction: column;
    /* margin-left: 0; */
  }
  .img-frame img {
    max-width: 45vw;
    /* height: 194px; */
  }
  .text-frame {
    width: 45vw;
    height: 179px;
    margin-left: 0;
    margin-top: 20.55px;
  }
  .text-frame::before {
    border-radius: 16.69px;
    padding: 1.39px;
  }
  .tf-p1 {
    font-size: 18px;
    margin-bottom: 12.59px;
  }
  .tf-p2 {
    line-height: 22px;
    font-size: 14px;
  }
  .text-frame-wrap {
    margin-left: 20.25px;
    margin-right: 15.75px;
  }
  .curvedArrow {
    margin-top: 155px;
    transform: rotate(20deg);
    right: 34px;
    display: none;
    /* position: relative; */
    z-index: 10;
  }
  .mg-top-200 {
    margin-top: 120px;
  }
  .mg-bottom-56 {
    margin-bottom: 40.3px;
  }

  .mg-top-100 {
    margin-top: 100.72px;
  }
  .card-header-row {
    justify-content: center;
  }
  .wg-text {
    text-align: center;
    /* font-size: 36px; */
    /* max-width: 50vw; */
    /* margin-bottom: 32px; */
  }
  .testnimonial-cont {
    margin-top: 32px;
    margin-left: 16px;
    overflow-y: hidden;
  }
  /* .testimonial-cards {
    flex: 0 0 400.99px;
    height: 260px;
  } */
  .spacer {
    flex: 0 0 250px; /* Adjust the width of the space before the first card */
  }

  /* .tc-cards {
    flex: 0 0 400.99px;
    height: 260px;
    border-radius: 12px;
  } */
  .testCard-inner {
    margin-left: 22.04px;
    margin-top: 22.04px;
    margin-right: 13px;
  }
  .testCard-head img {
    width: 40;
    height: 40;
  }
  .ts-name {
    font-size: 20px;
  }
  .ts-title {
    font-size: 14px;
  }
  .testCard-text {
    margin-left: 11px;
    /* background-color: red; */
    align-items: flex-start;
    display: block;
  }
  .feedback {
    font-size: 14px;
    line-height: 24px;
    flex: 0 0 277px;
    letter-spacing: 0.4px;
  }
  .main-cont {
    flex-direction: column;
    margin-top: 120px;
  }
  .main-cont img {
    width: 719px;
    height: 544px;
    animation: rotate-left-right 15s infinite;
  }
  .structure {
    flex-direction: column-reverse;
    margin-top: 32px;
  }
  .structure-cont1 {
    margin-right: 0;
    height: auto;
    margin-top: 48px;
  }
  .fact-text-section {
    margin-bottom: 24px;
  }
  .mg-FAQ {
    margin-top: 84px;
    margin-bottom: 32px;
  }
  .faq-container {
    width: 80vw;
  }
  .faq-item {
    margin-bottom: 16px;
    transition: background-color 0.3s;
    border: 1.5px solid #ffffff4d;
    border-radius: 12px;
    padding-top: 23px;
    padding-bottom: 23px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .faq-question p {
    font-size: 18px;
    width: 60vw;
    letter-spacing: 0.72px;
  }
  .faq-question svg {
    width: 24px;
    height: 24px;
  }
  .faq-answer {
    font-size: 14px;
    line-height: 24px;
    width: 261px;
  }

  .btn-cont {
    margin-top: 16px;
  }
  .cta {
    flex-direction: column;
    background-position: center;
    background-image: url("../assests/images/sctabanner.svg");
    width: 80vw;
    justify-content: center;
  }
  .cta-p {
    font-size: 36px;
    line-height: normal;
    text-align: center;
    padding-left: 0;
    height: auto;
    max-width: 232px;
    margin-top: 24px;
  }
  .cta-content {
    margin-left: 0;
    align-items: center;
    margin-top: 9.5px;
    margin-bottom: 19.5px;
  }
  .cta-content p {
    padding-right: 0;
    width: 279px;
    font-size: 15px;
    line-height: 26px;
    /* letter-spacing: 0.4px; */
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
  }
  /* .mt-text2 {
    margin-bottom: 16px;
  } */
  .footer-logo img {
    width: 114px;
    height: 30px;
    margin-top: 32px;
    /* margin-left: 24px; */
    margin-left: -10px;
  }
  .socials {
    width: 100%;
    margin-top: 40px;
  }
  .card-head {
    margin-top: 48.17px;
  }
  .card-p-mg-top2 {
    margin-top: 16px;
  }
  .card-p-mg-top3 {
    margin-top: 8px;
  }
  .ft-content {
    width: 100%;
    display: flex;
  }
  .card3 {
    margin-left: 0;
    /* background: red;y */
  }
  .fbtn-cont {
    width: 90%;
    /* justify-content: space-between; */
    margin-bottom: 40.79px;
  }
  .app-store-btn {
    margin-right: 16px;
  }
  .bottom-footer {
    width: 100%;
    /* background: red; */
    padding: 0;
    margin: 0;
  }
  .ptl {
    padding-left: 0;
    position: relative;
    left: 0;
  }
  .copyright {
    font-size: 11px;
    font-weight: 200;
    margin-top: 34px;
    line-height: 13px;
    margin-bottom: 24px;
    letter-spacing: 0%;
  }
  .cons-btn {
    display: none;
  }

  /* /////////////////// */
  /* ////////about us .// */
  /* /////////////////// */
  .abt-section {
    background-image: url("../assests/images/abtBg.svg");
    height: 100vh;
  }
  .abt-nav {
    padding-top: 44px;
  }
  .abt-nav svg {
    margin-left: 24px;
  }
  .abt-text-wrap {
    max-width: 85%;
  }
  .abt-g-text {
    font-size: 50px;
    margin-bottom: 24px;
    display: inline-block;
    /* max-width: 213px; */
    /* margin-top: 40px; */
  }
  .pp-section {
    background-image: url("../assests/images/policyBg.svg");
    background-position: top;
  }
  .top-space {
    height: 116px;
  }
  .policies-wrap {
    margin-bottom: 24px;
  }
  .policies-wrap p {
    letter-spacing: 0.4px;
    line-height: 26px;
  }
  .terms-use {
    background-image: url("../assests/images/termsBg.svg");
    background-position: top;
  }
  .aml-text {
    max-width: 100%;
  }
  .aml {
    background-image: url("../assests/images/amlBg.svg");
    background-position: top;
    height: 100vh;
  }
  .ctu-g-text {
    max-width: 100%;
    font-size: 50px;
  }
  .input-cont {
    width: 90vw;

    /* background: red; */
  }
  .send-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input {
    width: 90%;
    height: 80px !important;
  }
  .textarea-input {
    width: 90%;
    min-height: 150px;
  }
  .btn-long {
    width: 100%;
    height: 80px;
  }
  .ctu-section {
    background-image: url("../assests/images/ctuBg.svg");
    background-position: top;
    height: 100vh;
  }
  .mf-content {
    width: 100%;
    border: 1px solid #ffffff2e;
    /* background: red; */
    align-self: center;
    padding-left: 50px;
  }
  .footer-cont {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 912px) and (min-width: 821px) {
  .bodySection {
    background-image: url("../assests/images/mobilebg.png");
    background-position: top;
    background-size: cover;
    background-attachment: scroll;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
  }
  .section3 {
    /* background: red; */
    background-image: none;
  }
  .section2 {
    background: none;
    background-image: none;
  }
  .tz-project-section {
    background: none;
    background-image: none;
  }
  .rept {
    background: none;
  }
  .reserve-cont {
    background: none;
  }
  .factory-faq {
    background: none;
  }
  .navbar {
    /* background: red; */
    position: fixed;
  }
  .navBar-btn {
    margin-right: 0;
  }
  .navbar-links {
    display: none;
  }
  .login-signup {
    display: none;
  }
  /* .consult-btn{
    display: none;
  } */
  .container-fluid {
    /* background: blue; */
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    padding-left: 16px;
  }
  .navbar-brand {
    margin-left: 0;
  }
  .menu-toggle {
    display: flex;
  }
  .gradient-text {
    /* font-size: 50px; */
    text-align: center;
    margin-top: 84px;
    font-weight: 500;
    max-width: 100vw;
  }
  .text1 {
    margin-top: 32px;
  }
  .text-alt {
    margin-top: 10px;
    margin-bottom: 36px;
  }
  .mt-text {
    max-width: 70vw;
    /* font-size: 15px; */
    letter-spacing: 0.4px;
    line-height: 26px;
  }
  .innerTextSection {
    align-items: center;
    justify-content: center;
  }
  .bg-img-cont {
    /* background-image: none; */
    background-size: cover;
    width: 100%;
    height: 50vw;
    margin-top: 36px;
    flex-direction: column;
    align-items: center;
  }
  .store-btn-wrap {
    /* flex-direction: column; */
    margin-top: -30vw;
  }
  .app-store-btn {
    margin-right: 0;
    margin-bottom: 14px;
  }
  .section2 {
    /* background: red; */
  }
  .gradient-bg {
    display: none;
  }
  .himg {
    width: 100%;
  }
  .g-text {
    /* font-size: 36px; */
    margin-top: 120px;
    max-width: 100vw;
    text-align: center;
    letter-spacing: 2%;
    font-weight: 500;
    /* background-color: red; */
    /* display: block; */
  }
  .flow-btn-cont {
    display: none;
  }
  .text-alt2 {
    /* font-size: 36px; */
    font-weight: 500;
    margin-top: 2px;
    text-align: center;
    margin-bottom: 16px;
    /* background-color: aqua; */
  }
  .modal-section {
    flex-direction: column;
    align-items: center;
  }
  .wrap1 {
    margin-right: 0;
    margin-bottom: 32px;
  }
  .card-modal {
    /* max-width: 327px; */
    /* height: auto; */
  }
  .btn-sec svg {
    width: 42.05px;
    height: 42.05px;
  }
  .modal-hText {
    font-size: 24px;
  }
  .modal-pText {
    margin-top: 4px;
    /* font-size: 14px; */
    letter-spacing: 0.4px;
    line-height: 24px;
    padding-bottom: 16px;
    /* margin-bottom: 16px; */
  }
  .g-text2 {
    /* font-size: 36px; */
  }
  .mg-top-220 {
    margin-top: 120px;
  }
  .content-section {
    flex-direction: column;
  }
  .st-btn {
    width: 142.22px;
    height: 47.55px;
    margin-bottom: 10px;
  }
  .st-btn img {
    width: 18px;
  }
  .st-btn p {
    margin-left: 8px;
  }
  .content-frame {
    margin-left: 0px;
    margin-top: 24px;
    /* border-radius: 12px; */
  }
  .activeIndex {
    padding: 0;
    border-radius: 12px;
    margin: 0;
    border: 3px solid #fff;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .enlargeFrame {
    flex-direction: column;
    /* margin-left: 0; */
  }
  .img-frame img {
    max-width: 45vw;
    /* height: 194px; */
  }
  .text-frame {
    width: 45vw;
    height: 179px;
    margin-left: 0;
    margin-top: 20.55px;
  }
  .text-frame::before {
    border-radius: 16.69px;
    padding: 1.39px;
  }
  .tf-p1 {
    /* font-size: 18px; */
    margin-bottom: 12.59px;
  }
  .tf-p2 {
    line-height: 22px;
    font-size: 14px;
  }
  .text-frame-wrap {
    margin-left: 20.25px;
    margin-right: 15.75px;
  }
  .curvedArrow {
    margin-top: 155px;
    transform: rotate(20deg);
    right: 34px;
    display: none;
    /* position: relative; */
    z-index: 10;
  }
  /* .mg-top-200 {
    margin-top: 120px;
  }
  .mg-bottom-56 {
    margin-bottom: 40.3px;
  }

  .mg-top-100 {
    margin-top: 100.72px;
  } */
  .card-header-row {
    justify-content: center;
  }
  .wg-text {
    text-align: center;
    /* font-size: 36px; */
    /* max-width: 50vw; */
    /* margin-bottom: 32px; */
  }
  .testnimonial-cont {
    margin-top: 32px;
    margin-left: 16px;
    overflow-y: hidden;
  }
  /* .testimonial-cards {
    flex: 0 0 400.99px;
    height: 260px;
  } */
  .spacer {
    flex: 0 0 300px; /* Adjust the width of the space before the first card */
  }

  /* .tc-cards {
    flex: 0 0 400.99px;
    height: 260px;
    border-radius: 12px;
  } */
  .testCard-inner {
    margin-left: 22.04px;
    margin-top: 22.04px;
    margin-right: 13px;
  }
  .testCard-head img {
    width: 40;
    height: 40;
  }
  .ts-name {
    /* font-size: 20px; */
  }
  .ts-title {
    font-size: 14px;
  }
  .testCard-text {
    margin-left: 11px;
    /* background-color: red; */
    align-items: flex-start;
    display: block;
  }
  .feedback {
    /* font-size: 14px; */
    line-height: 24px;
    flex: 0 0 277px;
    letter-spacing: 0.4px;
  }
  .main-cont {
    flex-direction: column;
    margin-top: 120px;
  }
  .main-cont img {
    width: 719px;
    height: 544px;
    animation: rotate-left-right 15s infinite;
  }
  .structure {
    flex-direction: column-reverse;
    margin-top: 32px;
  }
  .structure-cont1 {
    margin-right: 0;
    height: auto;
    margin-top: 48px;
  }
  .fact-text-section {
    margin-bottom: 24px;
  }
  .mg-FAQ {
    margin-top: 84px;
    margin-bottom: 32px;
  }
  .faq-container {
    width: 80vw;
  }
  .faq-item {
    margin-bottom: 16px;
    transition: background-color 0.3s;
    border: 1.5px solid #ffffff4d;
    border-radius: 12px;
    padding-top: 23px;
    padding-bottom: 23px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .faq-question p {
    /* font-size: 18px; */
    width: 60vw;
    letter-spacing: 0.72px;
  }
  .faq-question svg {
    width: 24px;
    height: 24px;
  }
  .faq-answer {
    /* font-size: 14px; */
    line-height: 24px;
    width: 261px;
  }

  .btn-cont {
    margin-top: 16px;
  }
  .cta {
    flex-direction: column;
    background-position: center;
    background-image: url("../assests/images/sctabanner.svg");
    width: 80vw;
    justify-content: center;
  }
  .nmp {
    max-width: 70vw;
    text-align: center;
  }
  .cta-p {
    font-size: 36px;
    line-height: normal;
    text-align: center;
    padding-left: 0;
    height: auto;
    max-width: 232px;
    margin-top: 24px;
  }
  .cta-content {
    margin-left: 0;
    align-items: center;
    margin-top: 9.5px;
    margin-bottom: 19.5px;
  }
  .cta-content p {
    padding-right: 0;
    width: 279px;
    font-size: 15px;
    line-height: 26px;
    /* letter-spacing: 0.4px; */
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
  }
  .card3 {
    margin-top: 10px;
  }
  /* .mt-text2 {
    margin-bottom: 16px;
  } */
  .footer-logo img {
    width: 114px;
    height: 30px;
    margin-top: 32px;
    /* margin-left: 24px; */
    margin-left: -10px;
  }
  .socials {
    width: 100%;
    margin-top: 40px;
  }
  .card-head {
    margin-top: 48.17px;
  }
  .card-p-mg-top2 {
    margin-top: 16px;
  }
  .card-p-mg-top3 {
    margin-top: 8px;
  }
  .ft-content {
    width: 100%;
    display: flex;
  }
  .card3 {
    margin-left: 50px;
  }
  .card4 {
    margin-left: 0;
  }
  .fbtn-cont {
    width: 90%;
    /* justify-content: space-between; */
    margin-bottom: 40.79px;
  }
  .app-store-btn {
    margin-right: 16px;
  }
  .footer-bottom {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ptl {
    position: relative;
    padding-left: 0;
    padding-top: 20px;
  }

  .copyright {
    font-weight: 200;
    margin-top: 34px;
    line-height: 13px;
    margin-bottom: 24px;
    letter-spacing: 0%;
  }
  .cons-btn {
    display: none;
  }

  /* /////////////////// */
  /* ////////about us .// */
  /* /////////////////// */
  .abt-section {
    background-image: url("../assests/images/abtBg.svg");
    height: 100vh;
  }
  .abt-nav {
    padding-top: 44px;
  }
  .abt-nav svg {
    margin-left: 24px;
  }
  .abt-text-wrap {
    max-width: 85%;
  }
  .abt-g-text {
    font-size: 50px;
    margin-bottom: 24px;
    display: inline-block;
    /* max-width: 213px; */
    /* margin-top: 40px; */
  }
  .pp-section {
    background-image: url("../assests/images/policyBg.svg");
    background-position: top;
  }
  .top-space {
    height: 116px;
  }
  .policies-wrap {
    margin-bottom: 24px;
  }
  .policies-wrap p {
    letter-spacing: 0.4px;
    line-height: 26px;
  }
  .terms-use {
    background-image: url("../assests/images/termsBg.svg");
    background-position: top;
  }
  .aml-text {
    max-width: 100%;
  }
  .aml {
    background-image: url("../assests/images/amlBg.svg");
    background-position: top;
    height: 100vh;
  }
  .ctu-g-text {
    max-width: 100%;
    font-size: 50px;
  }
  .input-cont {
    width: 90vw;

    /* background: red; */
  }
  .send-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input {
    width: 90%;
    height: 80px !important;
  }
  .textarea-input {
    width: 90%;
    min-height: 200px;
  }
  .btn-long {
    width: 100%;
    height: 80px;
  }
  .ctu-section {
    background-image: url("../assests/images/ctuBg.svg");
    background-position: top;
    height: 100vh;
  }
  .mf-content {
    width: 100%;
    border: 1px solid #ffffff2e;
    /* background: red; */
    align-self: center;
    padding-left: 50px;
  }
  .footer-cont {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1024px) and (min-width: 913px) {
  .card-modal {
    width: 45vw;
  }
  .btn-sec {
    width: 45vw;
  }
  .card-modal svg {
    margin-bottom: 16px;
  }
  .store-frame {
    width: 45vw;
    background-size: cover;
  }
  .content-frame {
    width: 45vw;
    justify-content: center;
  }
  /* .content-frame img{
    width: 45vw;
  } */
  .emma {
    width: 45vw;
    margin-top: 10px;
  }
  .frameText {
    margin-top: 64px;
    margin-bottom: 16px;
  }
  .text-frame {
    width: 40vw;
  }
  .enlargeFrame {
    /* background-color: red; */
  }
  .img-frame img {
    width: 65vw;
  }
  .curvedArrow {
    right: 100px;
    display: none;
    margin-top: -120px;
  }
  .wg-text {
    width: 65vw;
  }
  .flow-btn-cont {
    margin-left: 20px;
  }
  .spacer {
    /* background: red; */
    flex: 0 0 390px;
  }
  .main-cont img {
    width: 40vw;
    height: 40vw;
    /* height: 50vw; */
  }
  .cta {
    width: 95%;
  }
  .card4,
  .card5 {
    margin-top: 24px;
  }
  .structure-cont1 {
    height: auto;
    margin-right: 32px;
  }
  .fact-text-wrap {
    margin-bottom: 30px;
  }
  .fact-text-wrap p:last-child {
    width: 35vw;
  }
  .fact-text-section {
    /* background: red; */
  }
  .footer-cont {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  /* .structure-cont1 img{
   width: 0vw;
  } */
}

@media screen and (max-width: 1300px) and (min-width: 1025px) {
  /* .spacer{
    background: red;
    flex: 0 0 70px;
  } */
  .curvedArrow {
    right: 200px;
  }
  .main-cont-text {
    margin-left: 16px;
  }
}
