.toast-container {
    width: 100%;
    height: 400px;
    position: fixed;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 80000;
    /* background-color: red; */
    top: 0;
  }
  
  .toast-card {
    width: 327px;
    background-color: #1a1a17;
    border-radius: 8px;
    border: 1px solid #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    border-left-width: 5px;
    margin-right: 40px;
    margin-top: 40px;
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  
  .toast-card.success {
    border-left-color: #47ce75;
  }
  
  .toast-card.error {
    border-left-color: #BA2543;
  }
  
  .toast-card.show {
    opacity: 1;
    transform: translateX(0);
  }
  
  .icon {
    margin-left: 12px;
  }
  
  .toast-header-text {
    font-size: 14px;
    font-family: 'Libre Franklin', sans-serif;
    text-align: left;
    width: 90%;
    margin-left: 12px;
    color: white;
  }
  




  @media screen and (max-width: 767px) {
    .toast-container{
        justify-content: center;
    }
    .toast-card{
        margin-right: 0px !important;
    }
  }